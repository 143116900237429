import { Box, Divider, useTheme } from "@mui/material";
import { memo } from "react";
import Text from "./Typography/Text";

export const PatientDetailWrapper = memo(({ children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        borderRadius: "24px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
        height: "90%",
        marginTop: "24px",
        background: theme.palette.background.paper,
      }}>
      {children}
    </Box>
  );
});

export const PatientDetailHeader = memo(({ title, children }) => {
  return (
    <>
      <Box
        sx={{
          padding: "24px",
          display: "flex",
          justifyContent: "space-between",
          height: "70px",
        }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Text fontWeight={700} variant="h2">
            {title}
          </Text>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>{children}</Box>
      </Box>
      <Divider />
    </>
  );
});
