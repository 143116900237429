import { apiDelete, apiGet } from "./base";

export const fetchOpenEncounters = async ({ pageParam }) => {
  const url = `/api/encounters/?open=true`;
  const response = await apiGet(pageParam ?? url);
  return response.data;
};

export const fetchCpts = async () => {
  const url = `/api/lookup?type=cpt`;
  const response = await apiGet(url);
  return response.data?.results;
};

export const fetchIcds = async () => {
  const url = `/api/lookup?type=icd`;
  const response = await apiGet(url);
  return response.data?.results;
};

export const fetchEncountersByPatient = async (patientId, { pageParam }) => {
  const url = `/api/patients/${patientId}/encounters/`;
  const response = await apiGet(pageParam ?? url);
  return response.data;
};

export const deleteOpenEncounter = async (encounterId) => {
  const url = `/api/encounters/${encounterId}/`;
  const response = await apiDelete(url);
  return response.data;
};
