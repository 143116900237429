import * as Yup from "yup";

export const OrderMedicationsSchema = Yup.object({
  patient_id: Yup.number().nullable().required("Patient is required"),
  refills: Yup.number()
    .typeError("Refills must be a number")
    .required("Refills is required")
    .min(0, "Refills must be at least 0")
    .max(500, "Refills must be at most 500"),
  dispensable_drug_id: Yup.string().required("Medication is required"),
  related_diagnosis: Yup.string().required("Related Diagnosis is required"),
  drug_name: Yup.string().required("Drug Name is required"),
  dosage: Yup.number()
    .typeError("Dosage must be a number")
    .required("Dosage is required"),
  frequency: Yup.number()
    .typeError("Frequency must be a number")
    .required("Frequency is required"),
  encounter_id: Yup.number().nullable().required("Encounter is required"),
  pharmacy_id: Yup.number().nullable().required("Pharmacy is required"),
});
