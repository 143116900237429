import { Box, Grid } from "@mui/material";
import lock from "assets/svg/light/lock.svg";
import Button from "components/common/Button/Button";
import InputField from "components/common/FormComponents/InputField";
import Text from "components/common/Typography/Text";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createNewPasswordAPI,
  getCSRF,
} from "redux/features/Auth/creatNewPasswordSlice";
import { CreateNewPasswordValidationSchema } from "validations/auth/login/createNewPassword.schema";
import LoginWrapper from "./LoginWrapper";

const CreateNewPassword = () => {
  const navigate = useNavigate();
  const { hiddenToken } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const { creatNewPasswordsSuccess, creatNewPasswordsIsLoading } = useSelector(
    (state) => state.createNewPassword,
  );

  const formik = useFormik({
    initialValues: {
      new_password: "",
      confirm_password: "",
    },
    validationSchema: CreateNewPasswordValidationSchema,
    onSubmit: (values) => {
      if (hiddenToken) {
        dispatch(
          createNewPasswordAPI(values, hiddenToken, queryParams.get("isReset")),
        );
      } else {
        toast.error("Invalid Url");
      }
    },
  });

  useEffect(() => {
    dispatch(getCSRF());
    localStorage.removeItem("forPassTime");
    localStorage.removeItem("forPassEmail");
  }, []);

  useEffect(() => {
    creatNewPasswordsSuccess && toast.success("Password changed");
    creatNewPasswordsSuccess && navigate("/login");
  }, [creatNewPasswordsSuccess]);

  return (
    <LoginWrapper>
      <Box
        sx={{
          maxWidth: "100%",
          width: "400px",
          marginTop: "50px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <Box>
          <Text id={"password-heading"} center marginY={2} variant="h1">
            Create a New Password
          </Text>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}>
            <Box sx={{ maxWidth: "315px" }}>
              <Text
                id={"password-validation-msg"}
                center
                variant="bodyS"
                marginY={2}
                sx={{
                  display: "flex",
                  whiteSpace: "normal",
                  lineHeight: "1.5",
                  textAlign: "center",
                }}>
                Make sure it's 8 characters long and includes a mix of letters,
                numbers and symbols.
              </Text>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItmes: "center",
            maxWidth: "300px",
            marginTop: "20px",
          }}>
          <Grid container spacing={1}>
            <InputField
              xs={12}
              sm={12}
              md={12}
              type={"password"}
              lg={12}
              placeholder={"Enter new password"}
              formik={formik}
              name="new_password"
              iconDetails={{ allowIcon: true, iconName: lock }}
              positionLeft
            />
            <InputField
              xs={12}
              sm={12}
              md={12}
              type={"password"}
              lg={12}
              placeholder={"Re-enter new password"}
              formik={formik}
              name="confirm_password"
              iconDetails={{ allowIcon: true, iconName: lock }}
              positionLeft
            />
          </Grid>
          <Box
            sx={{
              padding: "8px 0px 0px 0px",
              width: "100%",
              marginTop: "10px",
            }}>
            <Button
              id="save-password-btn"
              isLoading={creatNewPasswordsIsLoading}
              text="Save Password"
              sx={{
                width: "100%",
                height: "38px",
                "&:hover": {
                  color: "#fff",
                },
                backgroundImage:
                  "linear-gradient(270deg, #4C2631 1.99%, #285677 90.93%)",
                color: "#FFFFFF",
                border: "none",
              }}
              onClick={formik.handleSubmit}
            />
          </Box>
        </Box>
      </Box>
    </LoginWrapper>
  );
};

export default CreateNewPassword;
