export function DoseSpotLogo({ width = "119px", height = "28px" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 119 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3885_108040)">
        <path
          d="M12.2718 7.35902C11.1782 5.98607 9.25939 5.10269 7.31531 5.10269C5.37124 5.10269 3.5637 5.86486 2.27271 7.253C0.926001 8.70667 0.212158 10.7006 0.212158 13.0175C0.212158 17.6664 3.18909 21.0383 7.29009 21.0383C9.40124 21.0383 11.2036 20.1752 12.272 18.6659V20.791H16.3323V0.211466H12.2718V7.35902ZM8.28731 17.5151C6.35332 17.5151 4.30278 15.9705 4.30278 13.0175C4.30278 10.0646 6.36846 8.63107 8.28731 8.63107C10.2213 8.63107 12.3022 10.0192 12.3022 13.0731C12.3022 16.127 10.2214 17.5151 8.28731 17.5151Z"
          fill="#146EB6"
        />
        <path
          d="M25.7138 5.10269C21.0254 5.10269 17.7498 8.37869 17.7498 13.068C17.7498 17.7573 20.9798 21.0333 25.6022 21.0333C30.2248 21.0333 33.6775 17.6815 33.6775 13.068C33.6775 8.45443 30.402 5.10269 25.7138 5.10269ZM25.6022 17.5402C23.2988 17.5402 21.8102 15.7836 21.8102 13.068C21.8102 9.76673 23.8809 8.59567 25.6581 8.59567C28.3515 8.59567 29.5614 10.8419 29.5614 13.068C29.5614 16.1572 27.5717 17.5402 25.6022 17.5402Z"
          fill="#146EB6"
        />
        <path
          d="M41.7984 11.6193C40.1176 11.1851 38.6645 10.8067 38.6645 9.70119C38.6645 8.80771 39.4392 8.29797 40.7909 8.29797C42.2339 8.29797 43.1957 9.03992 43.3074 10.2312V10.2868H47.1501V10.2211C46.9171 7.06639 44.5224 5.10283 40.9025 5.10283C37.2825 5.10283 34.7662 7.01594 34.7662 9.75173C34.7662 13.1035 37.8342 13.8707 40.2949 14.4916C41.9858 14.9156 43.444 15.2791 43.444 16.3593C43.444 17.2779 42.5427 17.8736 41.1505 17.8736C39.7583 17.8736 38.5786 17.0559 38.4419 15.8847V15.8292H34.4066V15.8948C34.5839 18.8781 37.4343 21.0385 41.1809 21.0385C44.9273 21.0385 47.2867 19.1607 47.2867 16.3593C47.2207 13.0276 44.2135 12.2453 41.7984 11.6193Z"
          fill="#146EB6"
        />
        <path
          d="M61.5235 7.19244V7.19756C60.1359 5.82462 58.2377 5.10283 56.03 5.10283C51.3823 5.10283 48.2588 8.30304 48.2588 13.0681C48.2588 15.4203 49.018 17.4243 50.456 18.868C51.8483 20.2662 53.828 21.0335 56.03 21.0335C59.569 21.0335 62.3436 19.08 63.2699 15.9302L63.2952 15.8495H58.9869L58.9716 15.8849C58.3843 17.0912 57.3666 17.7021 55.949 17.7021C53.9494 17.7021 52.5366 16.3643 52.3291 14.2898H63.5431L63.5536 14.2393C63.6293 13.7901 63.665 13.3005 63.665 12.7351C63.665 10.4937 62.9258 8.58062 61.5235 7.19244ZM52.3595 11.5688C52.6737 9.61534 54.0303 8.40384 55.9191 8.40384C57.9592 8.40384 59.4324 9.70119 59.5133 11.5688H52.3595Z"
          fill="#146EB6"
        />
        <path
          d="M67.096 9.40843C67.096 7.84873 68.635 6.53619 70.8426 6.53619C73.5311 6.53619 74.9286 8.04046 75.0395 9.93839H76.3005C76.1281 7.12173 74.0323 5.47618 70.8731 5.47618C67.9114 5.47618 65.8404 7.28826 65.8404 9.40843C65.8404 15.1527 75.1509 12.7248 75.1509 16.9093C75.1509 18.5548 73.7539 19.948 71.3743 19.948C68.4988 19.948 66.9037 18.6658 66.7067 16.7426H65.4205C65.7038 19.534 68.0528 21.0382 71.3796 21.0382L71.3743 21.0333C74.2851 21.0333 76.3814 19.2817 76.3814 16.9093C76.3257 11.6092 67.096 14.0927 67.096 9.40843Z"
          fill="#F39921"
        />
        <path
          d="M86.9423 5.48126C83.616 5.48126 81.3227 7.35404 80.5125 9.66579V5.67812H79.2521V27.9537H80.5125V16.8588C81.3479 19.1454 83.616 21.0433 86.9423 21.0433C91.1902 21.0433 94.2684 18.0349 94.2684 13.2346C94.2684 8.43422 91.1954 5.48126 86.9423 5.48126ZM86.7453 19.9228C83.221 19.9228 80.5073 17.2727 80.5073 13.2295C80.5073 9.18633 83.221 6.56657 86.7453 6.56657C90.4357 6.56657 92.9823 9.04504 92.9823 13.2295C92.9823 17.4141 90.2686 19.9228 86.7453 19.9228Z"
          fill="#F39921"
        />
        <path
          d="M103.493 5.47618C99.2403 5.47618 95.9697 8.37356 95.9697 13.2295C95.9697 18.0854 99.1846 21.0383 103.437 21.0383C107.69 21.0383 110.961 18.0551 110.961 13.2295C110.961 8.40394 107.746 5.47618 103.493 5.47618ZM103.437 19.9228C100.248 19.9228 97.2559 17.8027 97.2559 13.2295C97.2559 8.65636 100.273 6.56657 103.463 6.56657C106.652 6.56657 109.7 8.62609 109.7 13.2295C109.7 17.833 106.627 19.9228 103.437 19.9228Z"
          fill="#F39921"
        />
        <path
          d="M118.788 6.82398V5.72358H115.239V1.82672H113.963V5.72856H111.639V6.82896H113.968V16.9647C113.968 19.9883 115.244 21.0635 117.877 21.0635H118.788V19.9329H118.074C116.004 19.9329 115.239 19.2817 115.239 16.9648V6.82398H118.788Z"
          fill="#F39921"
        />
      </g>
      <defs>
        <clipPath id="clip0_3885_108040">
          <rect width="119" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
