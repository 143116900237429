import React, { useEffect, useRef, useState } from "react";
import { Box, useTheme } from "@mui/material";
import Button from "components/common/Button/Button";
import CustomModal from "components/common/Modal/CustomModal";
import {
  clearLabsList,
  clearLabsTestData,
  deleteLabTest,
  getLabTests,
  getLabsList,
} from "redux/features/Labs/labsSlice";
import { useDispatch, useSelector } from "react-redux";
import { DEBOUNCE_DELAY, debounce } from "utils/debouncer";
import { OrderLabModal } from "../OrderLabModal";
import DraggableChip from "../DraggableChip.js/index.js";
import Text from "components/common/Typography/Text";
import moment from "moment";
import { useParams } from "react-router-dom";
import PlanMedicationModal from "../PlanMedicationModal";
import {
  clearOrderMedicationsData,
  getOrderMedicationsList,
} from "redux/features/Medications/OrderMedicationsSlice";
import {
  clearDeletePrescription,
  deletePrescriptionsByPatientId,
  getMedicinesList,
} from "redux/features/Medications/MedicinesSlice";
import { getPatientMedications } from "redux/features/Medications/MedicationsSlice";
import SimpleListMenu from "./ListMenu";
import { useQuery } from "@tanstack/react-query";
import { fetchCpts, fetchIcds } from "apiClients/encounters";

const EndVisitDetail = ({
  userLabEntries,
  setUserLabEntries,
  encounter,
  encounterData,
  setEncounterData,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { patientId } = useParams();
  const labAbortControllerRef = useRef(null);
  const medicationsAbortControllerRef = useRef(null);
  const [openLabModal, setOpenLabModal] = useState(false);
  const [openPrescModal, setOpenPrescModal] = useState(false);
  const [selectedPrescription, setSelectedPrescription] = useState("");
  const [labsOption, setLabsOption] = useState([]);
  const [icdsOption, setIcdsOption] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { labsData, deleteLabTestSuccess, labsTestData, labsIsLoading } =
    useSelector((state) => state.labs);
  const { medicinesData, medicinesIsLoading, deletedSuccess } = useSelector(
    (state) => state.medicines,
  );
  const { orderMedicationsList } = useSelector(
    (state) => state.orderMedications,
  );

  const { data: cptData, isLoading: isCptLoading } = useQuery({
    queryKey: ["cptsOptions"],
    queryFn: async () => await fetchCpts(),
  });

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  function abortLabsCurrentRequest() {
    if (labAbortControllerRef.current) {
      labAbortControllerRef.current.abort();
    }
  }

  const getLabs = debounce((value) => {
    if (value?.length > 0) {
      // Cancel previous request if it exists
      abortLabsCurrentRequest();

      // Create a new AbortController
      const abortController = new AbortController();
      labAbortControllerRef.current = abortController;

      dispatch(getLabsList(value, labAbortControllerRef.current.signal));
    }
  }, DEBOUNCE_DELAY);

  function _clearLabsData() {
    abortLabsCurrentRequest();
    dispatch(clearLabsList());
  }

  useEffect(() => {
    /**
     * Update the options with labs.
     * remove duplicate labs from the options
     */
    setLabsOption((prevState) => {
      if (labsTestData?.length) {
        const updatedOptions = labsData.map((option) => {
          const isSelected = labsTestData?.some(
            (lab) => lab?.name === option?.label.trim(),
          );
          return {
            ...option,
            disabled: isSelected,
          };
        });
        return [...prevState, ...updatedOptions];
      } else {
        return labsData;
      }
    });
  }, [labsData]);

  useEffect(() => {
    labsTestData && setUserLabEntries(labsTestData);
  }, [labsTestData]);

  useEffect(() => {
    if (encounterData?.smart_note_data?.diagnosis_data) {
      let diagnosisData = encounterData?.smart_note_data?.diagnosis_data;
      // Extract icd_10_code and map it to label and value
      const mappedDiagnosisData = Object.keys(diagnosisData).reduce(
        (acc, category) => {
          diagnosisData[category].forEach((diagnosis) => {
            if (diagnosis.icd_10_code) {
              acc.push({
                label: diagnosis.icd_10_code, // Use icd_10_code as label
                value: diagnosis.icd_10_code, // Use icd_10_code as value
              });
            }
          });
          return acc;
        },
        [],
      );
      setIcdsOption(mappedDiagnosisData);
    }
  }, [encounterData?.smart_note_data]);

  const excludeLabs = (val) => {
    dispatch(deleteLabTest(patientId, val));
  };

  const handleDeleteSuggestion = (key, itemToRemove) => {
    setEncounterData((prevData) => {
      // Create a shallow copy of prevData
      let updatedData = { ...prevData };

      // Create a shallow copy of smart_note_data
      updatedData.smart_note_data = { ...prevData.smart_note_data };

      // Handling for "medications" (Array of Strings)
      if (key === "medications") {
        updatedData.smart_note_data[key] = prevData.smart_note_data[key].filter(
          (medication) => medication !== itemToRemove,
        );
      }

      // Handling for "labs_to_order" (Array of Objects)
      else if (key === "labs_to_order") {
        updatedData.smart_note_data[key] = prevData.smart_note_data[key].filter(
          (lab) =>
            lab.lab_name !== itemToRemove.lab_name ||
            lab.icd_10_code !== itemToRemove.icd_10_code,
        );
      }

      // Handling for "diagnosis_data" (Nested Object with Categories)
      else if (key === "diagnosis_data") {
        let category = itemToRemove.category;

        // Create a deep copy of the diagnosis_data object
        updatedData.smart_note_data.diagnosis_data = {
          ...prevData.smart_note_data.diagnosis_data,
        };

        // Ensure category exists before modifying
        if (category in updatedData.smart_note_data.diagnosis_data) {
          updatedData.smart_note_data.diagnosis_data[category] =
            updatedData.smart_note_data.diagnosis_data[category].filter(
              (diagnosis) => diagnosis.condition !== itemToRemove.condition,
            );
        }
      } else if (key === "cpt_codes") {
        // Check if cpt_codes is a string
        if (typeof prevData.smart_note_data.cpt_codes === "string") {
          // Convert string to array and remove the item if it matches
          updatedData.smart_note_data.cpt_codes =
            prevData.smart_note_data.cpt_codes === itemToRemove
              ? ""
              : prevData.smart_note_data.cpt_codes;
        } else {
          // If it's already an array, filter out the item to remove
          updatedData.smart_note_data.cpt_codes =
            prevData.smart_note_data.cpt_codes.filter(
              (code) => code !== itemToRemove,
            );
        }
      }
      return updatedData; // Update state correctly
    });
  };

  //prescriptiom functions
  function abortMedicationsCurrentRequest() {
    if (medicationsAbortControllerRef.current) {
      medicationsAbortControllerRef.current.abort();
    }
  }

  const getMedicines = debounce((value) => {
    if (value?.length > 0) {
      // Cancel previous request if it exists
      abortMedicationsCurrentRequest();

      // Create a new AbortController
      const medicationsAbortController = new AbortController();
      medicationsAbortControllerRef.current = medicationsAbortController;
      dispatch(
        getMedicinesList(value, patientId, medicationsAbortController.signal),
      );
    }
  }, DEBOUNCE_DELAY);

  const excludeMedicine = (prescId) => {
    dispatch(deletePrescriptionsByPatientId(patientId, prescId));
  };

  const addCptNIcds = (isIcd = false, icdCptData) => {
    if (isIcd) {
      const newDiagnosis = {
        plan: [],
        status: "Unknown", // default status; you can change this as needed
        category: "",
        condition: icdCptData?.condition,
        icd_10_code: icdCptData?.icd_10_code,
        recommendations: [],
        pertinent_findings: [],
        recommendations_v2: [],
        status_pertinent_findings: [],
        manually_added: true,
      };
      setEncounterData((prevData) => {
        let updatedData = {
          ...prevData,
          smart_note_data: {
            ...prevData.smart_note_data,
            diagnosis_data: {
              ...prevData.smart_note_data.diagnosis_data,
            },
          },
        };

        if (updatedData.smart_note_data.diagnosis_data.other) {
          // Create a new array to avoid mutating the original array
          updatedData.smart_note_data.diagnosis_data.other = [
            ...updatedData.smart_note_data.diagnosis_data.other,
            newDiagnosis,
          ];
        } else {
          updatedData.smart_note_data.diagnosis_data.other = [newDiagnosis];
        }

        return updatedData;
      });
    } else {
      setEncounterData((prevData) => {
        let updatedData = {
          ...prevData,
          smart_note_data: {
            ...prevData.smart_note_data,
          },
        };

        // Check if cpt_codes is a string
        if (typeof updatedData.smart_note_data.cpt_codes === "string") {
          // Convert string to array and add the new CPT code
          updatedData.smart_note_data.cpt_codes = [
            updatedData.smart_note_data.cpt_codes,
            `manual ${icdCptData}`,
          ];
        } else {
          // If it's already an array, push the new CPT code into the array
          updatedData.smart_note_data.cpt_codes = [
            ...updatedData.smart_note_data.cpt_codes,
            `manual ${icdCptData}`,
          ];
        }

        return updatedData;
      });
    }
  };

  useEffect(() => {
    return () => {
      abortMedicationsCurrentRequest();
    };
  }, []);

  useEffect(() => {
    deleteLabTestSuccess && dispatch(getLabTests(patientId, encounter?.id));
    deleteLabTestSuccess && dispatch(clearLabsTestData());
  }, [deleteLabTestSuccess]);

  useEffect(() => {
    deletedSuccess &&
      dispatch(getOrderMedicationsList(patientId, encounter?.id));
    deletedSuccess && dispatch(getPatientMedications(patientId));
    dispatch(clearDeletePrescription());
  }, [deletedSuccess]);

  const menuData = () => {
    if (cptData?.length) {
      let data = cptData;
      let mappedData = data?.map((item) => {
        let isDisabled = false;
        if (typeof encounterData?.smart_note_data?.cpt_codes == "string") {
          isDisabled =
            encounterData?.smart_note_data?.cpt_codes.replace("manual ", "") ==
            item?.value;
        } else {
          isDisabled = encounterData?.smart_note_data?.cpt_codes
            ?.map((cptCode) => cptCode?.replace("manual ", ""))
            ?.includes(item?.value);
        }
        return {
          label: item?.description,
          value: item?.value,
          isDisabled,
          onClick: () => {
            addCptNIcds && addCptNIcds(false, item?.value);
            handleCloseMenu();
          },
        };
      });
      return mappedData;
    }
  };

  return (
    <Box
      sx={{
        height: "60vh",
        maxHeight: "59vh",
        overflowY: "auto",
      }}>
      <Box sx={{ position: "relative" }}>
        <Box sx={{ display: "flex", gap: 3 }}>
          <Box
            marginY={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              marginBottom: 4,
            }}>
            <Text variant="h4">Encounter Date</Text>
            <Text variant="h3">
              {encounter?.datetime
                ? moment(encounter?.datetime).format("MM-DD-YYYY")
                : "---"}
            </Text>
          </Box>
          <Box
            marginY={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              marginBottom: 4,
            }}>
            <Text variant="h4">Follow Up Date</Text>
            <Text variant="h3" sx={{ whiteSpace: "normal" }}>
              {encounter?.smart_note_data?.follow_up_instructions ?? "---"}
            </Text>
          </Box>
        </Box>
        <Box marginY={2}>
          <DiagnosisSection
            title={"DIAGNOSIS"}
            onAdd={addCptNIcds}
            data={encounterData?.smart_note_data?.diagnosis_data}
            onDelete={handleDeleteSuggestion}
          />
        </Box>
        <Box marginY={2}>
          <Text variant="h3" fontWeight={700}>
            CPT CODES
          </Text>
          {encounterData?.smart_note_data?.cpt_codes && (
            <>
              {Array.isArray(encounterData.smart_note_data.cpt_codes) ? (
                encounterData.smart_note_data.cpt_codes.map((code, index) => (
                  <Box
                    marginY={1}
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <DraggableChip
                      key={index}
                      {...(code?.includes("manual")
                        ? {
                            bgColor: theme.palette.common.lightGrey,
                            color: theme.palette.common.black,
                            hoverBgColor: theme.palette.common.lightGrey,
                            hoverTextColor: theme.palette.common.black,
                            hoverDeleteIconColor: theme.palette.common.black,
                          }
                        : {
                            bgColor: theme.palette.ambientSayaColor.main,
                            color: theme.palette.common.white,
                          })}
                      label={code?.replace("manual", "")}
                      onDelete={() => handleDeleteSuggestion("cpt_codes", code)}
                    />
                  </Box>
                ))
              ) : (
                <Box
                  marginY={1}
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <DraggableChip
                    bgColor={theme.palette.ambientSayaColor.main}
                    color={theme.palette.common.white}
                    label={encounterData.smart_note_data.cpt_codes}
                    onDelete={() =>
                      handleDeleteSuggestion(
                        "cpt_codes",
                        encounterData.smart_note_data.cpt_codes,
                      )
                    }
                  />
                </Box>
              )}
            </>
          )}
          <Box>
            <Button
              sx={{ marginTop: 1, minWidth: "50px", width: "50px" }}
              variant="text"
              text={"+ Add"}
              onClick={handleOpenMenu}
            />
            <SimpleListMenu
              isLoading={isCptLoading}
              anchorEl={anchorEl}
              onClose={handleCloseMenu}
              menuItems={menuData()}
            />
          </Box>
        </Box>
        <Box marginY={2}>
          <Text variant="h3" fontWeight={700}>
            LABS
          </Text>
          {encounterData?.smart_note_data?.labs_to_order?.length > 0 && (
            <>
              {encounterData?.smart_note_data?.labs_to_order.map((lab, i) => (
                <Box marginY={1} key={i}>
                  <DraggableChip
                    sx={{
                      backgroundColor: theme.palette.ambientSayaColor.main,
                      color: theme.palette.common.white,
                    }}
                    bgColor={theme.palette.ambientSayaColor.main}
                    color={theme.palette.common.white}
                    label={`${lab?.lab_name} ${lab?.icd_10_code ? lab?.icd_10_code : ""}`}
                    onDelete={() =>
                      handleDeleteSuggestion("labs_to_order", lab)
                    }
                  />
                </Box>
              ))}
            </>
          )}
          {userLabEntries?.map((lab, i) => {
            return (
              <Box key={i} marginY={1}>
                <DraggableChip
                  bgColor={theme.palette.common.lightGrey}
                  color={theme.palette.common.black}
                  hoverBgColor={theme.palette.common.lightGrey}
                  hoverTextColor={theme.palette.common.black}
                  hoverDeleteIconColor={theme.palette.common.black}
                  label={`${lab?.name} ${lab?.icd_10_code ? ` - ${lab?.icd_10_code}` : ""}`}
                  onDelete={() => excludeLabs(lab?.id)}
                />
              </Box>
            );
          })}

          <Box>
            <Button
              sx={{ marginTop: 1, minWidth: "50px", width: "50px" }}
              variant="text"
              text={"+ Add"}
              onClick={() => setOpenLabModal(true)}
            />
          </Box>
        </Box>
        <Box marginY={2}>
          <Text variant="h3" fontWeight={700}>
            Prescription
          </Text>
          {encounterData?.smart_note_data?.medications_changes?.length > 0 && (
            <>
              {(encounterData?.smart_note_data?.medications_changes == null
                ? []
                : encounterData?.smart_note_data?.medications_changes
              ).map((meds, i) => (
                <Box marginY={1} key={i}>
                  <DraggableChip
                    bgColor={theme.palette.ambientSayaColor.main}
                    color={theme.palette.common.white}
                    sx={{}}
                    label={`${meds}`}
                    onDelete={() => handleDeleteSuggestion("medications", meds)}
                  />
                </Box>
              ))}
            </>
          )}
          {orderMedicationsList?.map((presc, i) => {
            return (
              <Box key={i} marginY={1}>
                <DraggableChip
                  bgColor={theme.palette.common.lightGrey}
                  color={theme.palette.common.black}
                  hoverBgColor={theme.palette.common.lightGrey}
                  hoverTextColor={theme.palette.common.black}
                  hoverDeleteIconColor={theme.palette.common.black}
                  label={`${presc?.drug_name} - ${presc?.related_diagnosis}`}
                  onDelete={() => excludeMedicine(presc?.id)}
                />
              </Box>
            );
          })}
          <Box>
            <Button
              sx={{ marginTop: 1, minWidth: "50px", width: "50px" }}
              variant="text"
              text={"+ Add"}
              onClick={() => setOpenPrescModal(true)}
            />
          </Box>
        </Box>
        {encounter?.id && encounter?.sign_close_ind === "c" && (
          <div
            style={{
              position: "absolute",
              top: "20px",
              left: 0,
              bottom: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgb(253 250 250 / 40%)",
              zIndex: 1299,
              pointerEvents: "auto",
              cursor: "not-allowed",
            }}></div>
        )}
      </Box>
      <CustomModal
        setOpen={() => {
          setSelectedPrescription(null);
          setOpenPrescModal(false);
          dispatch(clearOrderMedicationsData());
        }}
        open={openPrescModal}
        title="Add Prescription"
        fullWidth
        maxWidth="md">
        <PlanMedicationModal
          icdsOption={icdsOption}
          abortMedicationsCurrentRequest={abortMedicationsCurrentRequest}
          setSelectedPrescription={setSelectedPrescription}
          selectedPrescription={selectedPrescription}
          setShowMedicationModal={setOpenPrescModal}
          medicinesData={medicinesData}
          medicinesIsLoading={medicinesIsLoading}
          getMedicines={getMedicines}
          encounter={encounter}
        />
      </CustomModal>
      <CustomModal
        title={"Order Labs"}
        open={openLabModal}
        setOpen={setOpenLabModal}
        maxWidth="sm"
        fullWidth={true}>
        <OrderLabModal
          labsIsLoading={labsIsLoading}
          getLabs={getLabs}
          clearLabsData={_clearLabsData}
          encounter={encounter}
          icdsOption={icdsOption}
          options={labsOption}
          setOpenLabModal={setOpenLabModal}
        />
      </CustomModal>
    </Box>
  );
};

export default EndVisitDetail;

const DiagnosisSection = ({
  title,
  onAdd,
  data: suggestedIcds = [],
  onDelete,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const { data: icdData, isLoading } = useQuery({
    queryKey: ["icdsOptions"],
    queryFn: async () => await fetchIcds(),
  });

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const menuData = () => {
    if (icdData?.length) {
      let data = icdData;
      let mappedData = data?.map((item) => {
        const isDisabled = Object.values(suggestedIcds).some((category) =>
          category.some((diagnosis) => diagnosis.icd_10_code == item?.value),
        );
        return {
          label: item?.description,
          value: item?.value,
          isDisabled: isDisabled, // check if it already includes then show disabled tru item?.value
          onClick: () => {
            onAdd &&
              onAdd(true, {
                condition: item?.description,
                icd_10_code: item?.value,
              });
            handleCloseMenu();
          },
        };
      });
      return mappedData;
    }
  };

  return (
    <Box marginY={1}>
      <Text variant="h3" fontWeight={700}>
        {title}
      </Text>
      {suggestedIcds && Object.keys(suggestedIcds)?.length > 0 && (
        <>
          {Object.keys(suggestedIcds).map((category) =>
            suggestedIcds[category].length > 0 ? (
              <Box key={category} marginY={1}>
                {suggestedIcds[category].map((diagnosis) => (
                  <Box
                    key={diagnosis.icd_10_code}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      marginY: 1,
                    }}>
                    <DraggableChip
                      {...(diagnosis.manually_added
                        ? {
                            bgColor: theme.palette.common.lightGrey,
                            color: theme.palette.common.black,
                            hoverBgColor: theme.palette.common.lightGrey,
                            hoverTextColor: theme.palette.common.black,
                            hoverDeleteIconColor: theme.palette.common.black,
                          }
                        : {
                            bgColor: theme.palette.ambientSayaColor.main,
                            color: theme.palette.common.white,
                          })}
                      label={`${diagnosis.icd_10_code}`}
                      onDelete={() =>
                        onDelete("diagnosis_data", {
                          condition: diagnosis.condition,
                          category: category,
                        })
                      }
                    />
                    <Text variant="h3" fontWeight={500}>
                      {diagnosis.condition}
                    </Text>
                  </Box>
                ))}
              </Box>
            ) : null,
          )}
        </>
      )}
      <Box>
        <Button
          sx={{ marginTop: 1, minWidth: "50px", width: "50px" }}
          variant="text"
          text={"+ Add"}
          onClick={handleOpenMenu}
        />
        <SimpleListMenu
          isLoading={isLoading}
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          menuItems={menuData()}
        />
      </Box>
    </Box>
  );
};
