import { Box, Grid, useTheme } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React, { useEffect, useRef, useState } from "react";
import Text from "components/common/Typography/Text";
import Button from "components/common/Button/Button";
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  deleteOpenEncounter,
  fetchOpenEncounters,
} from "apiClients/encounters";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { DeleteConfirmationModal } from "components/common/Modal/DeleteConfirmationModal";
import PendingIcon from "assets/svg/bold/pending";
import dummyPic from "../../assets/images/profileAvatar.png";
import { dateFormat } from "utils/date";
import DeleteIcon from "assets/svg/light/deleteIcon";
import UpperHeader from "./components/UpperHeader";
import { useDispatch } from "react-redux";
import { setEncounterOpenCountDetails } from "redux/features/Encounter/EncounterSlice";

const OpenEncountersDashboard = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [totalCount, setTotalCount] = useState(0);
  const [lastEncounter, setLastEncounter] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteEncounter, setDeleteEncounter] = useState(null);

  const { data, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ["openEncounters"],
    queryFn: async (data) => fetchOpenEncounters(data),
    staleTime: 10 * 60 * 1000, // 10 minutes stale time
    getNextPageParam: (lastPage) => lastPage.next,
  });

  const deleteMutation = useMutation({
    mutationFn: async (encounterId) => {
      return deleteOpenEncounter(encounterId);
    },

    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: ["openEncounters"],
      });
    },
  });

  const observer = useRef(
    new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // Fetch more data when the element is in view
          fetchNextPage();
        }
      },
      { threshold: 0.8 },
    ),
  );

  useEffect(() => {
    const currentObserver = observer.current;
    const currentRef = lastEncounter;

    if (currentRef) {
      currentObserver.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        currentObserver.unobserve(currentRef);
      }
    };
  }, [lastEncounter]);

  const getPatientName = (encounter) => {
    if (!encounter?.patient_first_name && !encounter?.patient_last_name) {
      return "N/A";
    }
    return `${encounter?.patient_first_name} ${encounter?.patient_last_name}`;
  };

  const _visibleData = () => {
    return (
      data?.pages.reduce((acc, curr) => {
        return [...acc, ...curr.results];
      }, []) ?? {}
    );
  };

  const visibleData = _visibleData();

  const onConfirmDelete = async () => {
    await deleteMutation.mutateAsync(deleteEncounter.id);
    setDeleteModalOpen(false);
  };

  useEffect(() => {
    setTotalCount(data?.pages?.[0]?.count);
    dispatch(
      setEncounterOpenCountDetails({
        count: data?.pages?.[0]?.count,
        isLoading: isFetching,
      }),
    );
  }, [data]);

  return (
    <>
      <DeleteConfirmationModal
        open={deleteModalOpen}
        onConfirm={() => onConfirmDelete()}
        onClose={() => setDeleteModalOpen(false)}>
        {`Are you sure you want to delete this encounter for ${getPatientName(deleteEncounter)}?`}
      </DeleteConfirmationModal>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <UpperHeader
            title="Open Encounters"
            IconComponent={() => (
              <Box
                sx={{
                  padding: "3px 3px",
                  marginRight: "9px",
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "24px",
                  height: "24px",
                  background: theme.palette.common.blue,
                }}>
                <PendingIcon
                  fill={theme.palette.common.white}
                  width="18"
                  height="18"
                  style={{ color: theme.palette.common.grey }}
                />
              </Box>
            )}>
            <Text variant="h2" sx={{ marginRight: "20px", marginTop: "4px" }}>
              Total: {totalCount}
            </Text>
          </UpperHeader>
          {isFetching && !hasNextPage && (
            <Box
              sx={{
                padding: "20px",
                background: theme.palette.background.paper,
                borderRadius: "18px",
              }}>
              <EncounterSkeleton />
            </Box>
          )}
          {visibleData?.length ? (
            <>
              {visibleData?.map((encounter, i) => (
                <Box
                  key={i}
                  sx={{
                    padding: "8px",
                    borderRadius: "12px",
                    marginBottom: "10px",
                    marginTop: "10px",
                    background: theme.palette.background.paper,
                    overflow: "hidden",
                  }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                      <Box
                        sx={{
                          borderRadius: "100px",
                          width: "62px",
                          height: "63px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                        <img
                          src={dummyPic}
                          alt="Profile"
                          data-testid="patient-picture"
                          style={{
                            width: "46px",
                            height: "46px",
                            borderRadius: "100px",
                            backgroundSize: "contain",
                            objectFit: "cover",
                            objectPosition: "50% 0",
                          }}
                        />
                      </Box>
                      <InfoBox
                        label="Patient Name"
                        value={getPatientName(encounter)}
                      />
                      <InfoBox
                        label="Encounter Date"
                        value={
                          encounter?.datetime
                            ? moment(encounter?.datetime).format(dateFormat)
                            : "N/A"
                        }
                      />
                      <InfoBox
                        label="Encounter Edited on"
                        value={
                          encounter?.datetime
                            ? moment(encounter.datetime).format(
                                `${dateFormat} HH:mm`,
                              )
                            : "N/A"
                        }
                      />
                      <InfoBox
                        label="Created By"
                        value={
                          encounter?.user_name ? encounter?.user_name : "N/A"
                        }
                      />
                      <InfoBox
                        label="Reason for Visit"
                        value={
                          encounter?.visit_reason
                            ? encounter?.visit_reason
                            : "N/A"
                        }
                      />
                    </Box>
                    <Box sx={{ display: "flex", gap: 1, paddingRight: "8px" }}>
                      <Button
                        text={"Resume"}
                        onClick={() => {
                          navigate(
                            encounter?.smart_note_data !== null
                              ? `/patients/${encounter?.patient_id}?encounter_id=${encounter?.id}&review_later=true`
                              : `/patients/${encounter?.patient_id}?encounter_id=${encounter?.id}`,
                          );
                        }}
                      />
                      <Box
                        onClick={() => {
                          setDeleteEncounter(encounter);
                          setDeleteModalOpen(true);
                        }}
                        sx={{
                          padding: "8px 12px 7px 12px",
                          borderRadius: "10px",
                          border: `1px solid ${theme.palette.common.grey}`,
                          cursor: "pointer",
                        }}>
                        <DeleteIcon />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
              <Box
                ref={setLastEncounter}
                sx={{
                  background: theme.palette.background.paper,
                  padding: 2,
                  borderRadius: "18px",
                }}>
                {" "}
                {isFetching ? <EncounterSkeleton /> : ""}
              </Box>
            </>
          ) : (
            <>{!isFetching && <Box>No data found </Box>}</>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export { OpenEncountersDashboard };

const EncounterSkeleton = () => {
  return (
    <Box>
      <Box flex={1} sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Skeleton height={71} width={43} sx={{ borderRadius: "50%" }} />
        <Box flex={1}>
          <Skeleton height={30} />
          <Skeleton height={30} />
        </Box>
        <Box flex={1}>
          <Skeleton height={30} />
          <Skeleton height={30} />
        </Box>
        <Box flex={1}>
          <Skeleton height={30} />
          <Skeleton height={30} />
        </Box>
        <Box flex={1}>
          <Skeleton height={30} />
          <Skeleton height={30} />
        </Box>
        <Box flex={1}>
          <Skeleton height={30} />
          <Skeleton height={30} />
        </Box>
      </Box>
    </Box>
  );
};

const InfoBox = ({ label, value, minWidth = "54px" }) => {
  const theme = useTheme();

  return (
    <Box gap={"5px"} display={"flex"} flexDirection={"column"}>
      <Text variant="body" color={"grey"} minWidth={minWidth}>
        {label}
      </Text>
      <Text variant="bodyBold">{value ? value : "N/A"}</Text>
    </Box>
  );
};
