import { Box, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Skeleton from "@mui/material/Skeleton";
import TabPill from "components/common/Tabs/TabPill";
import { useTheme } from "@mui/material";
import Text from "components/common/Typography/Text";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLabsResults } from "redux/features/Patients/ViewLabResultsSlice";
import LabResultDetailCard from "./LabResultDetailCard";
import { dateFormat } from "utils/date";
import { PatientDetailHeader } from "components/common/PatientDetailWrapper";
import Button from "components/common/Button/Button";

const LabResultDetail = ({
  setComponentName,
  patientName = "",
  patientID = null,
  setSelectedPatientID,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { labResults, isLabResultsLoading } = useSelector(
    (state) => state.resultsLabs,
  );

  useEffect(() => {
    return () => {
      setSelectedPatientID && setSelectedPatientID(null);
    };
  }, []);

  useEffect(() => {
    patientID && !labResults?.length && dispatch(getLabsResults(patientID));
  }, [dispatch, patientID]);

  const createLabsData = labResults?.map((d, i) => {
    return {
      title: `${moment(d?.lab_observed_datetime).format(dateFormat)}`,
      tab: (
        <LabResultDetailCard
          name={patientName}
          data={d ?? []}
          active={i === 0 ? true : false}
        />
      ),
      value: i,
    };
  });

  function closePreviousLabResults() {
    setComponentName(null);
  }

  return (
    <Box>
      {
        <>
          <PatientDetailHeader title="Previous Lab Results">
            <Button
              onClick={closePreviousLabResults}
              variant="outlined"
              text="Close"
              sx={{
                width: "105px",
                minWidth: "100px",
                marginRight: "15px",
              }}
            />
          </PatientDetailHeader>
          {isLabResultsLoading ? (
            <SkeletonLoader />
          ) : createLabsData?.length ? (
            <Box sx={{ padding: "10px 15px" }}>
              <TabPill
                tabsSectionSx={{ width: "120px" }}
                multipleTitles={false}
                componentTitle={"Lab Results"}
                tabTitle={{
                  title: "Date",
                  tabTitleSx: { marginLeft: "12px", marginBottom: "9px" },
                }}
                childrenArray={createLabsData}
                defaultValue={0}
                backgroundColor="#ffffff"
                indicatorBackgroundColor={"#1344F1"}
                tabSelectedColor={"#1344F1"}
                paddingBtn="0px"
                showBorder={false}
                tabSx={{ color: theme.palette.common.blue }}
              />
            </Box>
          ) : (
            <Box padding={3} textAlign={"center"}>
              No Data Found
            </Box>
          )}
        </>
      }
    </Box>
  );
};

export default LabResultDetail;

const SkeletonLoader = () => {
  return (
    <>
      <Box
        data-testid="lab-loading"
        sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
        <Box sx={{ width: "100%" }} marginX={1} marginY={1}>
          <Grid container spacing={1}>
            <Grid item lg={4} md={4} xs={4} sm={4}>
              <Card
                elevation={0}
                sx={{
                  width: " 100%",
                  border: "1px solid #e2e2e4",
                  background: "#fafafb",
                }}>
                <CardHeader
                  subheader={
                    <>
                      <Skeleton
                        sx={{ marginBottom: "5px" }}
                        animation="wave"
                        height={50}
                        width="100%"
                      />
                      <Skeleton
                        sx={{ marginBottom: "5px" }}
                        animation="wave"
                        height={50}
                        width="100%"
                      />
                      <Skeleton
                        sx={{ marginBottom: "5px" }}
                        animation="wave"
                        height={50}
                        width="100%"
                      />
                      <Skeleton
                        sx={{ marginBottom: "5px" }}
                        animation="wave"
                        height={50}
                        width="100%"
                      />
                      <Skeleton
                        sx={{ marginBottom: "5px" }}
                        animation="wave"
                        height={50}
                        width="100%"
                      />
                    </>
                  }
                />
              </Card>
            </Grid>
            <Grid item md={8} xs={8} sm={8} lg={8} spacing={1}>
              {[1, 2, 3].map((v, memberIndex) => (
                <Card
                  key={memberIndex}
                  elevation={0}
                  sx={{
                    width: " 100%",
                    border: "1px solid #e2e2e4",
                    background: "#fafafb",
                    marginBottom: "5px",
                  }}>
                  <CardHeader
                    subheader={
                      <>
                        <Skeleton
                          sx={{ marginBottom: "5px" }}
                          animation="wave"
                          height={15}
                          width="75%"
                        />
                        <Skeleton
                          sx={{ marginBottom: "5px" }}
                          animation="wave"
                          height={15}
                          width="50%"
                        />
                        <Skeleton
                          sx={{ marginBottom: "5px" }}
                          animation="wave"
                          height={15}
                          width="25%"
                        />
                      </>
                    }
                  />
                </Card>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
