import React, { useState } from "react";
import {
  Box,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,
  TableSortLabel,
  Skeleton,
  Pagination,
  useTheme,
} from "@mui/material";
import { isArrayEmpty } from "utils/helper";

const TableBodyLoader = (columns) => {
  return (
    <>
      {Array(9)
        .fill("")
        .map((_, index) => (
          <TableRow key={index}>
            {columns.map((column, i) => {
              return (
                <TableCell key={column.key}>
                  <Skeleton variant="rounded" sx={{ height: "30px" }} />
                </TableCell>
              );
            })}
          </TableRow>
        ))}
    </>
  );
};

const DataTable = ({
  columns,
  loading,
  readOnly,
  totalCount,
  onPageChange,
  onSortChange, // Callback for sorting
  data = [],
  page,
  pageSize,
}) => {
  const theme = useTheme();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  // Handle sorting callback
  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);

    // Trigger the sorting function passed via props
    onSortChange && onSortChange(property, newOrder);
  };

  const emptyDataTable = isArrayEmpty(data);
  return (
    <Box>
      <Box sx={{ border: `1px solid ${theme.palette.common.borderColor}` }}>
        <TableContainer
          sx={{
            backgroundColor: theme.palette.common.white,
            scrollbarWidth: "thin",
            minHeight: "400px",
            maxHeight: "calc(100vh - 258px)",
          }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns?.map((column) => (
                  <TableCell
                    key={column?.key}
                    sx={{
                      minWidth: column.width,
                      width: "max-content",
                      fontWeight: "bold",
                      backgroundColor: theme.palette.common.dataTableHeaderbg,
                      pr: 1,
                    }}>
                    {column.sortable !== false ? (
                      <TableSortLabel
                        sx={{ width: "max-content", fontWeight: "bold" }}
                        active={orderBy === column.key}
                        direction={orderBy === column.key ? order : "asc"}
                        onClick={() => handleSortRequest(column.key)}>
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                TableBodyLoader(columns) // Render skeleton loader
              ) : !emptyDataTable ? (
                data?.map((row, index) => (
                  <TableRow
                    key={index}
                    onClick={() => {
                      if (readOnly) return;
                    }}
                    sx={{
                      cursor: readOnly ? "normal" : "pointer",
                      ":hover": {
                        backgroundColor: theme.palette.common.borderColor,
                      },
                      "& .MuiTableCell-root": {
                        padding: "8px 8px 8px 16px",
                      },
                    }}>
                    {columns.map((column) => (
                      <TableCell key={column.key} sx={{ pr: 1 }}>
                        {column.format
                          ? column.format(row)
                          : (row[column.key] ?? "-")}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}>
          <Pagination
            componentName="div"
            showFirstButton
            showLastButton
            page={page}
            defaultPage={1}
            count={Math.ceil(totalCount / pageSize)}
            color="primary"
            onChange={(_, newPage) => onPageChange(newPage)}
            sx={{
              backgroundColor: theme.palette.common.white,
              display: "flex",
              height: "60px",
              "& .MuiButtonBase-root.Mui-selected": {
                backgroundColor:
                  theme.palette.dataTableColor.dataTablepaginationbg,
                color: theme.palette.common.black,
                "&:hover": {
                  backgroundColor:
                    theme.palette.dataTableColor.dataTablepaginationbg,
                  color: theme.palette.common.black,
                },
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DataTable;
