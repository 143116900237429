import moment from "moment";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import Editor from "components/Editor/Editor.js";
import { useSearchParams } from "react-router-dom";
import { forwardRef, useEffect, useState } from "react";
import { convertJsonToHtml } from "utils/convertJsonToHtml";

const EndVisit = forwardRef(
  ({ encounter, encounterData, userLabEntries }, ref) => {
    const [searchParams] = useSearchParams();
    const reviewLaterParam = searchParams.get("review_later");
    const [planNoteData, setPlanNoteData] = useState("");

    // To remove the glitch of showing `no data` if it takes time to generate plan note.
    const [isPlanNoteGenerating, setIsPlanNoteGenerating] = useState(
      encounter?.plan_note ? false : true,
    );

    const { getSpecificPatientData } = useSelector(
      (state) => state.specificPatient,
    );

    const { orderMedicationsList } = useSelector(
      (state) => state.orderMedications,
    );

    function setDataDetails(encounter) {
      setIsPlanNoteGenerating(true);
      if (encounter?.plan_note && encounter?.plan_note.startsWith("Powered")) {
        setPlanNoteData(encounter?.plan_note || "");
        setIsPlanNoteGenerating(false);
      } else {
        let data = reviewLaterParam ? encounter : encounterData;
        const smartNoteData = data?.smart_note_data || "";
        setPlanNoteData(
          convertJsonToHtml(
            {
              name: `${getSpecificPatientData?.first_name} ${getSpecificPatientData?.last_name}`,
              age_gender: `${moment().diff(getSpecificPatientData?.birthdate, "years")}-year-old ${getSpecificPatientData?.gender}`,
            },
            // it check if the JSON that is coming from back is stringify or not.
            typeof smartNoteData !== "string"
              ? smartNoteData
              : JSON.parse(removeEscapeCharacters(smartNoteData || "{}")),
            userLabEntries,
            orderMedicationsList,
          ),
        );
        setIsPlanNoteGenerating(false);
      }
    }

    function removeEscapeCharacters(str) {
      /**
       * This function is used to remove escape characters from the string
       * @first replace all the \n with empty string
       * @second replace all the data enclosed in ""$"" to "$"
       * @third replace all the "" with ""
       */
      let modifiedStr = str
        ?.replace(/\\n/g, "")
        .replace(/""(.*?)""/g, '"$1"')
        .replace(/""/g, '""');
      return modifiedStr;
    }

    useEffect(() => {
      if (encounter?.id) {
        setDataDetails(encounter);
      }
    }, []);

    return (
      <Box position={"relative"}>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              data-testid="scd-modal-richtexteditor"
              sx={{ marginTop: "5px", marginBottom: "15px", width: "100%" }}>
              {!isPlanNoteGenerating && (
                <Editor
                  ref={ref}
                  data={planNoteData}
                  mainSx={{
                    border: "1px solid #D1D1D1",
                    borderRadius: "10px",
                    height: "60vh",
                  }}
                  contentBoxSx={{
                    overflowY: "auto",
                    height: "88%",
                  }}
                  ShouldConvertFromMarkdownString={
                    encounter?.plan_note.startsWith("Powered") ? true : false
                  }
                />
              )}
            </Box>
          </Grid>
        </Grid>
        {encounter?.id && encounter?.sign_close_ind === "c" && (
          <div
            style={{
              position: "absolute",
              top: "20px",
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgb(253 250 250 / 40%)",
              zIndex: 1299,
              pointerEvents: "auto",
              cursor: "not-allowed",
            }}></div>
        )}
      </Box>
    );
  },
);

export default EndVisit;
