import { Box } from "@mui/material";
import moment from "moment";
import { dateFormat, incomingDateFormat } from "utils/date";

export const SmartSummary = ({ patientData }) => {
  const smartSummaryData = patientData?.smart_summary_data;
  const previousVisit = smartSummaryData?.previous_visit;
  const lastVisitSummary = previousVisit?.summary;
  const provider = previousVisit?.provider;
  const chiefComplaint = previousVisit?.chief_complaint;
  const lastEncounterDate = previousVisit?.date;
  const primaryDiagnosis = smartSummaryData?.primary_diagnosis;
  const medications = previousVisit?.med_mods;
  const outsideEncountersSinceLastEncounter =
    smartSummaryData?.outside_encounters_since_last_encounter;
  const importantLabs = smartSummaryData?.important_labs_v2;

  // Get number of dates we have values for (excluding first column and reference range)
  const numDates = importantLabs?.[0]
    ?.slice(1, -1) // Exclude first column and reference range
    .filter(
      (col) =>
        importantLabs
          .slice(1)
          .some((row) => row[importantLabs[0].indexOf(col)]?.value), // Check if the column has any values
    ).length;

  // Only take as many header columns as we have dates, plus the first column
  const headers = importantLabs?.[0]?.slice(0, numDates + 1).map((header) => {
    return header?.value?.replace(/\//g, "-");
  });

  const labRows = importantLabs?.slice(1).map((lab) => {
    // Only take as many columns as we have dates, plus the first column
    return lab?.slice(0, numDates + 1).map((labValues) => {
      return labValues.value;
    });
  });

  const hasLabValues = importantLabs?.slice(1).some(
    (row) => row.slice(1, -1).some((cell) => cell.value), // Check if any lab has values (excluding first column and reference range)
  );

  return (
    <Box>
      <h4 className="">Patient Overview</h4>
      {primaryDiagnosis && (
        <p>
          <strong>Primary Diagnosis:</strong> {primaryDiagnosis}
          {lastVisitSummary && (
            <>
              <br />
              {lastVisitSummary}
            </>
          )}
        </p>
      )}

      {lastEncounterDate && (
        <>
          <h4 className="section-heading">Last Visit Summary</h4>
          <p>
            <strong>Last Seen:</strong>{" "}
            {moment(lastEncounterDate, incomingDateFormat).format(dateFormat)}
          </p>
        </>
      )}
      {provider && (
        <p>
          <strong>Visit By:</strong> {provider}
        </p>
      )}
      {chiefComplaint && (
        <>
          <p>
            <strong>Patient Complaints:</strong>
          </p>
          <p>{chiefComplaint}</p>
        </>
      )}
      {medications && medications.length > 0 && (
        <>
          <strong>Medications</strong>
          <ul>
            {medications.map((medication, index) => (
              <li key={index} className="medication-item">
                {medication.status === "Pending" ? (
                  <>
                    <span className="medication-status-pending">Pending</span>
                    {" - "}
                    {medication.action_item}
                  </>
                ) : (
                  `${medication.status} - ${medication.action_item}`
                )}
              </li>
            ))}
          </ul>
        </>
      )}

      {outsideEncountersSinceLastEncounter?.length > 0 && (
        <>
          <h4 className="section-heading">Encounters</h4>
          <p>
            <strong>Since Last Visit:</strong>
          </p>
          <ul>
            {outsideEncountersSinceLastEncounter.map((encounter, index) => (
              <li key={index}>
                {encounter.encounter_type}
                Visit - {moment(encounter.date).format(dateFormat)}{" "}
                {encounter.reason}
              </li>
            ))}
          </ul>
        </>
      )}
      {importantLabs && importantLabs.length > 0 && hasLabValues && (
        <>
          <h4 className="section-heading">Recent Labs</h4>
          <table className="EditorTheme__table">
            <thead>
              <tr>
                {headers?.map((header, index) => (
                  <th
                    key={index}
                    className="EditorTheme__tableCell EditorTheme__tableCellHeader">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {labRows?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row?.map((cell, cellIndex) => (
                    <td key={cellIndex} className="EditorTheme__tableCell">
                      {cellIndex === 0 ? (
                        <strong>{cell === "eGFR" ? "eGFR (5.1)" : cell}</strong>
                      ) : (
                        cell || "-"
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </Box>
  );
};
