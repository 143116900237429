import { Box, useTheme } from "@mui/material";
import Button from "components/common/Button/Button";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import Text from "components/common/Typography/Text";
import moment from "moment";
import { toCapitalize } from "utils/toCapitalize";
import { toast } from "react-toastify";
import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import {
  postRecordingData,
  saveDictation,
} from "redux/features/AmbientAI/RecordingSlice";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import {
  convertBlob,
  pauseRecording,
  resumeRecording,
  startRecording,
  stopRecording,
} from "utils/recording";
import recordingAnime from "../../../../assets/images/recordingAnime.gif";
import pauseIcon from "../../../../assets/svg/light/pause-icon.svg";
import micIcon from "../../../../assets/svg/light/microphone-icon.svg";
import resumeIcon from "../../../../assets/svg/light/resume-icon.svg";
import { useStopwatch } from "react-timer-hook";
import AvatarPic from "../../../../assets/images/profileAvatar.png";
import {
  autoRecordFeature,
  isFeatureEnabled,
} from "../../../../utils/featureFlags";
import { useLocation } from "react-router-dom";
import { useNavigationBlocker } from "hooks/useNavigationBlocker";
import {
  setCurrentNavigationPath,
  setRecording,
} from "redux/features/globalStateSlice";
import { dateFormat } from "utils/date";
import PendingIcon from "assets/svg/bold/pending";
import { LogoIcon } from "assets/svg/Logo";

const SCDHeader = forwardRef(
  (
    {
      setIsPostCalled,
      patientId,
      startAppointment,
      setStartAppointment,
      setComponentName,
      componentName,
    },
    ref,
  ) => {
    const theme = useTheme();
    //get query params
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const encounter_id = queryParams.get("encounter_id");
    const reviewLater = queryParams.get("review_later");
    const chunks = useRef([]);
    const [viewWidth, setViewWidth] = useState(window.innerWidth);
    const mediaStream = useRef(null);
    const mediaRecorder = useRef(null);
    const [recordedUrl, setRecordedUrl] = useState("");
    const [isRecording, setIsRecording] = useState(false);
    const [isRecordingPaused, setIsRecordingPaused] = useState(false);
    const [recordingTimestamps, setRecordingTimestamps] = useState([]);
    const [endVisitFlag, setEndVisitFlag] = useState(false);

    useNavigationBlocker({
      when: isRecording || isRecordingPaused,
      message:
        "Your recording is still in progress. Are you sure you want to leave this page?",
    });
    /*State to stop auto-recording if the flag is
     false and show button to start recording by*/
    const [enableManualRecording, setEnableManualRecording] = useState(false);

    const { encounter, isEncounterLoading } = useSelector(
      (state) => state.encounter,
    );
    const { getSpecificPatientData, getSpecificPatientSuccess } = useSelector(
      (state) => state.specificPatient,
    );
    const { flagsData } = useSelector((state) => state.getFlags);
    const dispatch = useDispatch();
    const { listening, finalTranscript, resetTranscript } =
      useSpeechRecognition();

    const startListeningHandler = () => {
      SpeechRecognition.startListening({ continuous: true });
    };

    const stopListeningHandler = () => {
      SpeechRecognition.stopListening();
    };

    const handleListen = () => {
      if (!listening) {
        startListeningHandler();
      } else {
        stopListeningHandler();
      }
    };

    useEffect(() => {
      if (finalTranscript !== "") {
        dispatch(saveDictation(finalTranscript));
        resetTranscript();
      }
    }, [finalTranscript, resetTranscript]);

    useEffect(() => {
      if (recordedUrl && !isRecording) {
        convertBlob(recordedUrl)
          .then((recordedBlob) => {
            const defaultType = recordedBlob.type; // Dynamically use the default type
            // Prepare FormData with the correct format
            let formData = new FormData();
            let fileExtension = defaultType === "audio/webm" ? "webm" : "mp4"; // Decide extension based on type
            let file = new File(
              [recordedBlob],
              `audio_recording.${fileExtension}`,
              {
                type: defaultType,
              },
            );
            formData.append("recording_file", file);
            formData.append("times", JSON.stringify(recordingTimestamps));

            // Dispatch postRecordingData with encounterId and FormData
            setIsPostCalled("called");
            dispatch(postRecordingData(encounter?.id, formData));
          })
          .catch((error) => {
            console.error("Error:", error); // eslint-disable-line no-console
          });
      }
    }, [isRecording, recordedUrl]);

    useEffect(() => {
      const handleResize = () => {
        setViewWidth(window.innerWidth);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    const {
      seconds,
      minutes,
      hours,
      start: startTimer,
      pause: pauseTimer,
      reset: resetTimer,
    } = useStopwatch({ autoStart: false });

    const handleStartApp = async () => {
      let isMicrophoneAccessed = await startRecording(
        setRecordedUrl,
        setIsRecording,
        mediaStream,
        mediaRecorder,
        chunks,
      );
      if (isMicrophoneAccessed) {
        setStartAppointment(true);
        handleListen();
        setEnableManualRecording(false);
        dispatch(setRecording(true));
        setRecordingTimestamps((prev) => [
          ...prev,
          {
            type: "start",
            time: new Date(),
          },
        ]);
      } else {
        toast.error(
          "Microphone access is required to create smart notes. Please enable it in your browser settings",
        );
      }
    };

    function startRecordingProcess() {
      setComponentName(null);
      if (isFeatureEnabled(flagsData, autoRecordFeature)) {
        handleStartApp();
        startTimer();
      } else {
        setStartAppointment(true);
        setEnableManualRecording(true);
      }
    }

    useImperativeHandle(ref, () => ({
      startRecordingProcessFunc: startRecordingProcess,
    }));

    useEffect(() => {
      return () => dispatch(setRecording(false));
    }, []);

    const currentNavigationPath = useSelector(
      (state) => state.globalStates?.currentNavigationPath,
    );
    let isOnPastVisit = false;
    const pastVisitsBreadcrumb = "Past Visits";
    if (currentNavigationPath === pastVisitsBreadcrumb) {
      isOnPastVisit = true;
    }

    // Display the buttons only if:
    // 1. the user is not on past visits
    // 2. startAppointment is false AND encounter?.plan_note exists, OR
    // 3. encounter?.plan_note does not exist AND appointment started is false
    const showPastVisitButton = () => {
      if (isOnPastVisit) {
        return false;
      }
      if (!startAppointment || encounter?.plan_note) {
        return true;
      }
      return false;
    };

    const intakeFlag = location.state?.intakeFlag || false;

    return (
      <>
        <Box
          sx={{
            backgroundColor: "#FFFFFFBF",
            borderRadius: "24px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
            padding: "10px 15px",
          }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap" // Allow items to wrap
            gap={1}>
            <Box
              sx={{
                "@media (min-width: 1250px)": {
                  flex: 1,
                },
                "@media (max-width: 1249px)": {
                  flex: "unset",
                },
                flexWrap: viewWidth < 992 ? "wrap" : "nowrap",
              }}>
              <Box
                sx={{
                  display: "flex",
                  flex: viewWidth < 992 ? 4 : 2,
                  alignItems: "center",
                  flexWrap: "wrap",
                }}>
                <Box
                  sx={{
                    marginRight: "10px",
                    height: "64px",
                  }}>
                  <SkeletonWrapper
                    id={"patient-detail-header-name"}
                    condition={getSpecificPatientSuccess}
                    variant="circular"
                    props={[
                      SkeletonPropsStyleGenerator("80px", "80px", "", "10px"),
                    ]}>
                    <img
                      src={AvatarPic}
                      alt=""
                      style={{
                        borderRadius: "100%",
                        width: "64px",
                        height: "64px",
                      }}
                      data-testid="scd-header-patient-img-id"
                    />
                  </SkeletonWrapper>
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <SkeletonWrapper
                      id={"patient-detail-header-name"}
                      condition={getSpecificPatientSuccess}
                      props={[SkeletonPropsStyleGenerator("100px", "45px")]}>
                      <Text
                        id={"scd-patient-name"}
                        variant="h1"
                        color={"black"}
                        marginRight="10px">
                        {getSpecificPatientData?.first_name
                          ? `${getSpecificPatientData?.first_name}`
                          : ""}
                      </Text>
                      <span
                        style={{
                          fontSize: "1.375rem",
                          lineHeight: "2.063rem",
                        }}>
                        {getSpecificPatientData?.last_name
                          ? getSpecificPatientData?.last_name
                          : ""}
                      </span>
                    </SkeletonWrapper>
                    <SkeletonWrapper
                      id={"patient-detail-header-name"}
                      condition={getSpecificPatientSuccess}
                      props={[SkeletonPropsStyleGenerator("5px", "0px")]}>
                      <span style={{}} />
                    </SkeletonWrapper>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <SkeletonWrapper
                      id={"patient-detail-header-gender"}
                      condition={getSpecificPatientSuccess}
                      props={[
                        SkeletonPropsStyleGenerator(
                          "100px",
                          "45px",
                          "",
                          "10px",
                        ),
                      ]}>
                      <Text
                        id={"scd-patient-id"}
                        variant="bodyXs"
                        color={"lightBlack"}>
                        ID: {getSpecificPatientData?.emr_id ?? ""}
                      </Text>
                    </SkeletonWrapper>

                    <hr
                      style={{
                        height: "16px",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    />

                    <SkeletonWrapper
                      id={"patient-detail-header-gender"}
                      condition={getSpecificPatientSuccess}
                      props={[
                        SkeletonPropsStyleGenerator("100px", "45px", ""),
                      ]}>
                      <Text
                        id={"scd-patient-gender"}
                        variant="bodyXs"
                        color={"lightBlack"}>
                        {getSpecificPatientData?.gender
                          ? toCapitalize(getSpecificPatientData?.gender)
                          : ""}
                      </Text>
                    </SkeletonWrapper>
                    <hr
                      style={{
                        height: "16px",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    />
                    <SkeletonWrapper
                      id={"patient-detail-header-age"}
                      condition={getSpecificPatientSuccess}
                      props={[
                        SkeletonPropsStyleGenerator("100px", "45px", ""),
                      ]}>
                      <Text
                        id={"scd-patient-age"}
                        variant="bodyXs"
                        color={"lightBlack"}>
                        {getSpecificPatientData?.birthdate
                          ? `${moment(getSpecificPatientData?.birthdate).format(
                              dateFormat,
                            )} ( ${moment().diff(
                              getSpecificPatientData?.birthdate,
                              "years",
                            )} y.o.)`
                          : ""}
                      </Text>
                    </SkeletonWrapper>
                  </Box>
                </Box>
              </Box>
              <Box>
                <EncounterLifecycle
                  reviewLater={reviewLater}
                  encounter_id={encounter_id}
                  encounter={encounter}
                  isRecordingPaused={isRecordingPaused}
                  componentName={componentName}
                />
              </Box>
            </Box>
            <Box
              sx={{
                "@media (min-width: 1250px)": {
                  flex: 1,
                },
                "@media (max-width: 1249px)": {
                  flex: "unset",
                },
                flexWrap: viewWidth < 992 ? "wrap" : "nowrap",
              }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "5px",
                  borderRadius: "6px",
                  background: "#E6EFFF",
                }}>
                <span style={{ marginLeft: "5px", marginRight: "5px" }}>
                  <LogoIcon />
                </span>
                <span style={{ fontSize: "12px", fontWeight: 500 }}>
                  This tool supports clinical decision-making but does not
                  replace your independent judgment or serve as the sole basis
                  for diagnosis or treatment.
                </span>
              </Box>
            </Box>
            {intakeFlag || componentName == "patientIntake" ? (
              ""
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  flex: 1,
                  flexWrap: viewWidth < 992 ? "wrap" : "nowrap",
                }}>
                {!endVisitFlag && (
                  <SkeletonWrapper
                    id={"patient-detail-header-age"}
                    condition={!isEncounterLoading}
                    multipleCount={2}
                    sx={{ marginLeft: "12px" }}
                    props={[SkeletonPropsStyleGenerator("130px", "70px")]}>
                    {startAppointment && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: viewWidth < 992 ? "wrap" : "nowrap",
                          justifyContent: "flex-end",
                        }}>
                        {isRecording && (
                          <>
                            {isRecordingPaused ? null : (
                              <img
                                src={recordingAnime}
                                alt="anime file"
                                height={30}
                              />
                            )}
                            <Button
                              text={`${hours} : ${minutes} : ${seconds}`}
                              rightSide
                              iconDetails={{
                                allowIcon: true,
                                icon: isRecordingPaused
                                  ? resumeIcon
                                  : pauseIcon,
                                iconSx: {},
                              }}
                              sx={{
                                background: isRecordingPaused
                                  ? theme.palette.common.purple
                                  : theme.palette.common.softRed,
                                fontWeight: "700",
                                marginLeft: "15px",
                                marginTop: viewWidth < 992 ? "10px" : "",
                                "&:hover": {
                                  background: isRecordingPaused
                                    ? "#9D4BFF" // light purple; not yet a theme color.
                                    : "#e14e4e",
                                },
                              }}
                              onClick={
                                isRecordingPaused
                                  ? () => {
                                      resumeRecording(
                                        mediaStream,
                                        mediaRecorder,
                                        setIsRecording,
                                        setIsRecordingPaused,
                                      );
                                      dispatch(setRecording(true));
                                      startTimer();
                                      setRecordingTimestamps((prev) => [
                                        ...prev,
                                        {
                                          type: "start",
                                          time: new Date(),
                                        },
                                      ]);
                                    }
                                  : () => {
                                      pauseRecording(
                                        mediaStream,
                                        mediaRecorder,
                                        isRecordingPaused,
                                        setIsRecordingPaused,
                                      );
                                      pauseTimer();
                                      setRecordingTimestamps((prev) => [
                                        ...prev,
                                        {
                                          type: "stop",
                                          time: new Date(),
                                        },
                                      ]);
                                    }
                              }
                            />
                          </>
                        )}
                        {enableManualRecording && (
                          <Button
                            text={"Record"}
                            rightSide
                            iconDetails={{
                              allowIcon: true,
                              icon: micIcon,
                              iconSx: {},
                            }}
                            sx={{
                              padding: "8px",
                              background: theme.palette.common.purple,
                              fontWeight: "700",
                              marginLeft: "15px",
                              marginTop: viewWidth < 992 ? "10px" : "",
                              "&:hover": {
                                background: "#5b0fd1",
                              },
                            }}
                            onClick={() => {
                              handleStartApp();
                              startTimer();
                            }}
                          />
                        )}
                        {endVisitFlag ? (
                          ""
                        ) : !encounter?.smart_note_data && viewWidth > 850 ? (
                          <hr
                            style={{
                              marginLeft: "10px",
                              marginRight: "10px",
                              height: "52px",
                            }}
                          />
                        ) : (
                          ""
                        )}
                        {/* if appointment is started and encounter notes are generated then we will not gonna display the endivist button */}
                        {!encounter?.smart_note_data && (
                          <Button
                            id={"scd-end-visit-btn-id"}
                            text="End Visit"
                            sx={{
                              width: "150px",
                              padding: "0px 20px",
                              fontWeight: "700",
                              marginLeft: viewWidth < 850 ? "10px" : "",
                              marginTop: viewWidth < 992 ? "10px" : "",
                            }}
                            onClick={() => {
                              stopListeningHandler();
                              stopRecording(
                                mediaStream,
                                mediaRecorder,
                                setIsRecordingPaused,
                                setIsRecording,
                              );
                              dispatch(setRecording(false));
                              setIsRecording(false);
                              setRecordingTimestamps((prev) => [
                                ...prev,
                                {
                                  type: "stop",
                                  time: new Date(),
                                },
                              ]);
                              resetTimer();
                              setIsRecordingPaused(false);
                              setComponentName("endVisit");
                              setEndVisitFlag(true);
                            }}
                          />
                        )}
                      </Box>
                    )}
                    {!endVisitFlag &&
                      !encounter?.smart_note_data &&
                      !startAppointment && (
                        <>
                          <Button
                            text="Patient Intake"
                            variant="outlined"
                            sx={{
                              marginRight: "10px",
                              marginBottom: viewWidth > 850 ? "" : "10px",
                            }}
                            onClick={() => {
                              setComponentName("patientIntake");
                            }}
                          />
                          {showPastVisitButton() && (
                            <Button
                              text="Past Visits"
                              variant="outlined"
                              sx={{
                                marginRight: "10px",
                                marginBottom: viewWidth > 850 ? "" : "10px",
                              }}
                              id={"scd-review-past-note-btn-id"}
                              onClick={() => {
                                dispatch(
                                  setCurrentNavigationPath(
                                    pastVisitsBreadcrumb,
                                  ),
                                );
                                setComponentName("pastVisits");
                              }}
                            />
                          )}
                        </>
                      )}
                    {!startAppointment &&
                      (encounter?.smart_note_data === null ||
                        !encounter?.id) && (
                        <Button
                          onClick={() => startRecordingProcess()}
                          id={"scd-start-recording-btn-id"}
                          // checking the if the encounter id is present and the sign close ind is open
                          //this way we can make sure that this is not the first time recording
                          text={
                            encounter_id && encounter?.sign_close_ind === "o"
                              ? "Resume Visit"
                              : "Start Recording"
                          }
                          sx={{
                            marginRight: "10px",
                            marginBottom:
                              viewWidth > 850 &&
                              encounter_id &&
                              encounter?.sign_close_ind === "o"
                                ? ""
                                : viewWidth > 850
                                  ? ""
                                  : "10px",
                          }}
                        />
                      )}
                  </SkeletonWrapper>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </>
    );
  },
);

export default SCDHeader;

const generateEncounterLifeCycleSteps = ({
  reviewLater,
  encounter_id,
  encounter,
  theme,
  isRecordingPaused,
  componentName,
}) => {
  const steps = [];

  if (encounter?.id) {
    if (encounter?.sign_close_ind === "c") {
      // Encounter is closed
      steps.push({
        label: `Closed ${moment(encounter.datetime).format("MM-DD-YYYY hh:mm a")}`,
        isActive: false,
      });
    } else {
      steps.push({
        label: encounter?.datetime ? (
          <>
            <Text
              variant="formLabel"
              color={theme.palette.common.white}
              fontWeight={700}>
              Open -
            </Text>{" "}
            {moment(encounter.datetime).format(`${dateFormat} / hh:mm a`)}
          </>
        ) : (
          "Last Edited: N/A"
        ),
        isActive: true,
      });

      if (reviewLater || encounter?.plan_note) {
        // Step 3: Review Notes
        steps.push({ label: "Review Notes", isActive: false });
      } else if (
        encounter?.sign_close_ind === "o" &&
        (isRecordingPaused || encounter_id) &&
        componentName !== "endVisit"
      ) {
        // Step 3: Resume Recording
        steps.push({ label: "Resume Recording", isActive: false });
      } else if (
        encounter?.sign_close_ind === "o" &&
        !isRecordingPaused &&
        componentName == "endVisit"
      ) {
        steps.push({ label: "Finalize Note", isActive: false });
      }
    }
  }

  return steps;
};

const EncounterLifecycle = memo(
  ({
    componentName,
    reviewLater,
    encounter_id,
    isRecordingPaused,
    encounter,
  }) => {
    const [steps, setSteps] = useState([]);
    const theme = useTheme();

    useEffect(() => {
      setSteps(
        generateEncounterLifeCycleSteps({
          reviewLater,
          encounter_id,
          encounter,
          theme,
          isRecordingPaused,
          componentName,
        }),
      );
    }, [
      reviewLater,
      encounter_id,
      isRecordingPaused,
      componentName,
      encounter,
    ]);

    return (
      <>
        {steps?.length > 0 ? (
          <Box sx={{ display: "flex", alignItems: "center", marginY: 1 }}>
            {steps.map((step, index) => (
              <Box
                key={index}
                sx={{
                  background: step.isActive
                    ? theme.palette.primary.main
                    : theme.palette.common.lightGrey || "#E0E0E0",
                  color: step.isActive
                    ? theme.palette.common.white
                    : theme.palette.common.black,
                  borderTopLeftRadius: index === 0 ? "14px" : "0px",
                  borderBottomLeftRadius: index === 0 ? "14px" : "0px",
                  borderTopRightRadius:
                    index === steps.length - 1 ? "14px" : "0px",
                  borderBottomRightRadius:
                    index === steps.length - 1 ? "14px" : "0px",
                  padding: index === 0 ? "6px 6px" : "9px 6px",
                  display: "flex",
                  alignItems: "center",
                }}>
                {index === 0 && encounter?.sign_close_ind !== "c" && (
                  <PendingIcon
                    height="16px"
                    width="16px"
                    style={{ marginRight: "4px" }}
                    fill="#fff"
                  />
                )}
                <Text
                  center
                  variant="formLabel"
                  color={
                    step.isActive ? "white" : theme.palette.background.black
                  }
                  minWidth="54px">
                  {step.label}
                </Text>
              </Box>
            ))}
          </Box>
        ) : null}
      </>
    );
  },
);
