import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { flexRowCenterCenter } from "styles";
import Text from "../Typography/Text";

const useStyles = makeStyles((theme) => ({
  root1: (props) => ({
    borderRight: "none !important",
    minWidth: props?.tabsSectionSx?.width || "240px",
    width: props?.tabsSectionSx?.width || "240px",
    background: props.backgroundColor || "transparent",
    "& .MuiTabPanel-root": {
      padding: "0px !important",
    },
    "& .MuiTabs-scroller": {
      "& .MuiTabs-flexContainer.MuiTabs-flexContainerVertical": {
        borderRight: props.showBorder ? "1px solid #cccccc !important" : "",
        width: props?.tabsSectionSx?.width || "240px",
      },
    },
    "& .MuiTabs-indicator": {
      backgroundColor: `${props.tabColor} !important` || "#315FFF !important",
      width: "6px",
      borderRadius: !props.showBorder ? "20px" : "",
      borderTopLeftRadius: "20px",
      borderBottomLeftRadius: "20px",
    },
    "& .MuiButtonBase-root.MuiTab-root": {
      alignItems: "flex-start",
      textTransform: "capitalize",
      marginBottom: "8px",
      minWidth: props?.tabsSectionSx?.width || "240px",
      width: props?.tabsSectionSx?.width || "240px",
      paddingLeft: "0",
      backgroundColor: "transparent",
      color: "#666666 !important",
    },
    "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
      color: `${props.tabColor} !important` || "#1344F1 !important",
      fontWeight: "600",
      backgroundColor: "transparent",
    },
  }),
  root2: (props) => ({
    marginLeft: props?.contentSectionSx?.marginLeft || "30px",
    width: props?.contentSectionSx?.width || "auto",
  }),
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      style={{ width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      data-testid={`selected-tab-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ padding: "0px 15px" }}>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const TabPill = ({
  onTabChange = () => {},
  childrenArray = [],
  defaultValue = 0,
  iconPosition = "start",
  icon = null,
  tabSx = {},
  tabsSectionSx = {},
  contentSectionSx = {},
  tabTitle = { title: "", tabTitleSx: {} },
  componentTitle,
  tabOverFlowDetails,
  titleMargin = "0px",
  defaultStopRendering = false,
  showBorder = true,
  multipleTitles = true,
  numberedTabs = false,
  noContentDataTestId = "tabpill-data-not-found",
}) => {
  const theme = useTheme();
  const classes = useStyles({
    showBorder,
    tabsSectionSx,
    contentSectionSx,
    tabColor: tabSx?.color,
  });
  const [value, setValue] = useState(defaultValue);
  const { icon: IconComponent, props: iconProps } = icon || {};

  const handleChange = (_, newValue) => {
    if (!defaultStopRendering) {
      setValue(newValue);
      onTabChange && onTabChange(newValue);
    }
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  if (childrenArray.length === 0) {
    return (
      <Box data-testid={noContentDataTestId} padding={2}>
        <Box padding={3} textAlign={"center"}>
          No Data Found
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        minWidth: "200px",
      }}>
      <Box
        sx={{
          marginBottom: "20px",
          width: tabsSectionSx?.width || "240px",
        }}>
        {tabTitle?.title && (
          <Text
            fontWeight={700}
            color="textSecondary"
            sx={tabTitle.tabTitleSx}
            variant="bodyS">
            {tabTitle.title}
          </Text>
        )}
        <Tabs
          data-testid={"main-tab-comp"}
          orientation="vertical"
          value={value}
          onChange={handleChange}
          sx={{
            ...(showBorder
              ? {
                  borderRight: 1,
                  borderColor: "divider",
                  "& .MuiTabs-scroller.MuiTabs-fixed": tabOverFlowDetails?.sx,
                }
              : {}),
            ...tabsSectionSx,
          }}
          className={classes.root1}>
          {childrenArray.map(({ title, showIcon }, index) => (
            <Tab
              icon={
                showIcon && (
                  <IconComponent
                    {...iconProps}
                    {...(index === value
                      ? { fill: theme.palette.common.blue }
                      : {})}
                  />
                )
              }
              iconPosition={iconPosition}
              data-testid={`tab-${title}`}
              key={index}
              sx={{
                ...tabSx,
                ...(!showIcon && {
                  "& span.tab-title": { marginLeft: titleMargin },
                }),
              }}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {numberedTabs && (
                    <span
                      style={{
                        backgroundColor:
                          defaultValue === index
                            ? theme.palette.primary.main
                            : theme.palette.common.middleLightGrey,
                        color:
                          defaultValue === index
                            ? theme.palette.common.white
                            : theme.palette.common.black,
                        padding: "4px 10px",
                        borderRadius: "100px",
                        marginRight: "10px",
                      }}>
                      {" "}
                      {index + 1}
                    </span>
                  )}
                  <span className="tab-title">{title}</span>
                </Box>
              }
              {...a11yProps(value)}
              ref={
                index === childrenArray.length - 1
                  ? tabOverFlowDetails?.ref
                  : null
              }
            />
          ))}
          {tabOverFlowDetails?.isFetching ? (
            <Box
              sx={{
                ...flexRowCenterCenter,
                height: "20px",
                marginBottom: "20px",
                width: "100%",
              }}>
              Loading more...
            </Box>
          ) : (
            ""
          )}
        </Tabs>
      </Box>
      <Box
        sx={{
          flexGrow: contentSectionSx?.flexGrow ?? 1,
          width: contentSectionSx?.width || "auto",
          ...contentSectionSx,
        }}>
        {componentTitle && (
          <Text
            color={"grey"}
            sx={{ marginLeft: "45px" }}
            fontWeight={700}
            variant="bodyS">
            {multipleTitles ? componentTitle[defaultValue] : componentTitle}
          </Text>
        )}
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            borderRadius: "20px",
          }}
          className={classes.root2}>
          {childrenArray.map(({ tab }, index) => (
            <CustomTabPanel
              key={index}
              value={value}
              index={index}
              className={classes.tabPanel}>
              {tab}
            </CustomTabPanel>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default TabPill;
