import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const name = "Encounter";

export const initialEncounterState = {
  openEncountersCount: 0,
  encounter: {},
  encounterSuccess: null,
  encounterByAppointmentSuccess: null,
  encounterMessage: "",
  isEncounterLoading: false,
  isCloseEncounterPending: false,
  encounterCloseSuccess: false,
  intentToCloseEncounter: false,
  isEncounterByAppointmentLoading: false,
};

export const EncounterSlice = createSlice({
  name,
  initialState: initialEncounterState,
  reducers: {
    setEncounterOpenCountDetails: (state, { payload }) => {
      state.openEncountersCount = payload.count;
      state.openEncountersProcessing = payload.isLoading;
    },
    encounter: (state) => {
      state.isEncounterLoading = true;
    },
    closeEncounter: (state) => {
      state.isCloseEncounterPending = true;
    },
    closeEncounterSuccess: (state) => {
      state.isCloseEncounterPending = false;
      state.encounterCloseSuccess = true;
    },
    closeEncounterFail: (state) => {
      state.isCloseEncounterPending = false;
    },
    encounterByAppointment: (state) => {
      state.isEncounterByAppointmentLoading = true;
    },
    encounterSuccess: (state, action) => {
      state.encounter = action.payload;
      state.encounterSuccess = true;
      state.isEncounterLoading = false;
      state.encounterMessage = "Encounter created successfully";
    },
    setEncounterStatus: (state, { payload }) => {
      state.intentToCloseEncounter = payload;
    },
    encounterByAppointmentSuccess: (state, action) => {
      state.isEncounterByAppointmentLoading = false;
      state.encounterByAppointmentSuccess = true;
      state.encounter = action.payload;
    },
    encounterById: (state) => {
      state.isEncounterLoading = true;
    },
    encounterByIdSuccess: (state, action) => {
      state.encounter = action.payload;
      state.isEncounterLoading = false;
    },
    encounterByIdFail: (state) => {
      state.isEncounterLoading = false;
    },
    encounterFail: (state) => {
      state.encounterSuccess = false;
      state.isEncounterLoading = false;
      state.encounterMessage = "Unable to create encounter";
    },
    encounterByAppointmentFail: (state) => {
      state.isEncounterByAppointmentLoading = false;
      state.encounterByAppointmentSuccess = false;
      state.encounterByAppointmentData = {};
    },
    clearEncounterData: (state) => {
      state.encounter = {};
      state.encounterMessage = "";
      state.encounterSuccess = null;
      state.isEncounterLoading = false;
    },
    clearEncounterByPatientData: (state) => {
      state.encounterByPatientData = [];
    },
  },
});

export const {
  encounter,
  clearEncounterData,
  setEncounterOpenCountDetails,
  clearEncounterByPatientData,
  setEncounterStatus,
} = EncounterSlice.actions;

export default EncounterSlice.reducer;

export const createOrUpdateEncounter = (data, method, encounterId) => ({
  payload: {
    apiName: name,
    data,
    method: method,
    formData: false,
    url:
      method !== "POST"
        ? `/api/encounters/${encounterId}/`
        : `/api/encounters/`,
  },
  type: "encounter",
});

export const closeEncounter = (encounterId) => ({
  payload: {
    apiName: name,
    method: "post",
    url: `/api/encounters/${encounterId}/close/`,
  },
  type: "closeEncounter",
});

export const getEncounterByAppointment = (appointmentId) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/encounters/search_by_appointment_id/?appointment_id=${appointmentId}`,
  },
  type: "encounterByAppointment",
});

export const getEncounterById = (encounterId) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/encounters/${encounterId}/`,
  },
  type: "encounterById",
});
