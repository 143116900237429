import { apiDelete, apiGet, apiPost, apiPut } from "./base";

export const fetchPatientLabs = async (patientId, { pageParam }) => {
  const url = `/api/patients/${patientId}/labtestrequest/`;
  const response = await apiGet(pageParam ?? url);
  return response.data;
};

export const addLabTest = async (patientId, data) => {
  const url = `/api/patients/${patientId}/labtestrequest/`;
  const response = await apiPost(url, data);
  return response.data;
};

export const updateLabTest = async (patientId, labtestId, data) => {
  const url = `/api/patients/${patientId}/labtestrequest/${labtestId}/`;
  const response = await apiPut(url, data);
  return response.data;
};

export const deleteLabTest = async (patientId, labtestId) => {
  const url = `/api/patients/${patientId}/labtestrequest/${labtestId}/`;
  const response = await apiDelete(url);
  return response.data;
};
