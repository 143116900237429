import { Button, useTheme } from "@mui/material";

export const IconButton = ({
  disabled = false,
  onClick,
  children,
  variant = "outlined",
  title,
  sx = {},
  width = "30px",
  height = "30px",
}) => {
  const theme = useTheme();

  return (
    <Button
      disabled={disabled}
      title={title}
      onClick={onClick}
      sx={{
        width,
        height,
        minWidth: "unset",
        padding: "0",
        borderRadius: "10px",
        borderColor: theme.palette.common.grey,
        ...sx,
      }}
      variant={variant}>
      {children}
    </Button>
  );
};
