import MainLayout from "Layouts/AppContainer/Layout";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import CustomModal from "components/common/Modal/CustomModal";
import Button from "components/common/Button/Button";
import { Box } from "@mui/material";

const PrivateRoute = ({ theme }) => {
  const { loggedIn } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!loggedIn) {
      setShowModal(true);
    }
  }, [loggedIn]);

  const handleOkClick = () => {
    setShowModal(false);
    navigate("/login");
  };

  return (
    <>
      <CustomModal
        open={showModal}
        title="Session Expired"
        maxWidth="sm"
        fade={true}
        showCloseIcon={false}
        actions={[
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button onClick={handleOkClick} text={"Ok"} />
          </Box>,
        ]}
        sxDialog={{
          "& .MuiDialog-paper": {
            maxWidth: "400px",
          },
        }}
        sxDialogContent={{
          padding: "0px",
          minWidth: "auto",
        }}>
        <Box sx={{ padding: "15px" }}>
          Your session has ended. Please login again.
        </Box>
      </CustomModal>

      <MainLayout theme={theme}>
        <Outlet />
      </MainLayout>
    </>
  );
};

export default PrivateRoute;
