import { Alert, Box, Grid, useTheme } from "@mui/material";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  addPharmacy,
  deletePharmacy,
  fetchPharmacies,
  searchPharmacies,
} from "apiClients/pharmacies";
import Button from "components/common/Button/Button";
import CheckBoxField from "components/common/FormComponents/CheckBoxField";
import InputField from "components/common/FormComponents/InputField";
import SelectField from "components/common/FormComponents/SelectField";
import { Trash } from "components/common/SVG/Trash";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import Text from "components/common/Typography/Text";
import { useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPharmacyList } from "redux/features/Pharmacy/PharmacySlice";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import { debounce } from "utils/debouncer";
import { Required } from "validations/validators";

const PharmacyModal = ({ selectedPharmacyValue, setSelectedPharmacyValue }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  let { patientId } = useParams();
  const queryClient = useQueryClient();

  const { getSpecificPatientData = {} } = useSelector(
    (state) => state.specificPatient,
  );

  // Local states
  const [options, setOptions] = useState([]);
  // Set initial zipCode to empty so that the user sees an empty input when manual entry is allowed.
  const [zipCode, setZipCode] = useState("");
  const [searchedText, setSearchedText] = useState("");
  const [apiError, setApiError] = useState("");
  const [addedPharmacies, setAddedPharmacies] = useState(null);
  const [showAddPharmacy, setShowAddPharmacy] = useState(null);
  const [selectedPharmacy, setSelectedPharmacy] = useState(null);
  // State to control the number of skeleton loaders
  const [skeletonLoaderCount, setSkeletonLoaderCount] = useState(0);
  // New state: if true then use the patient zipcode and hide the input field.
  // By default the checkbox is unchecked.
  const [usePatientZip, setUsePatientZip] = useState(false);

  // Determine the zipcode to be used in the search query
  const searchZipCode = usePatientZip
    ? getSpecificPatientData?.zipcode || ""
    : zipCode;

  // Fetch the pharmacies data
  const {
    isLoading: isPharmaciesLoading,
    isFetching: isPharmaciesFetching,
    data: { results: pharmacyList } = {},
  } = useQuery({
    queryKey: ["pharmacies", patientId],
    queryFn: async () => await fetchPharmacies(patientId),
  });

  // Fetch the searched pharmacies data
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading: isSearchedPharmaciesLoading,
  } = useInfiniteQuery({
    queryKey: ["searchPharmacies", patientId, searchedText, searchZipCode],
    queryFn: async ({ pageParam = 1, signal }) => {
      // Fetch paginated pharmacy data
      const response = await searchPharmacies(
        patientId,
        searchedText,
        searchZipCode,
        pageParam,
        signal,
      );

      // Transform results to match the Redux structure
      const transformedIntoOptions = response?.results?.map(
        ({ pharmacy_name, pharmacy_id, address, phone }) => ({
          value: address,
          id: pharmacy_id,
          phone,
          label: pharmacy_name,
        }),
      );

      return {
        results: transformedIntoOptions,
        nextPage: response.next, // Update with the API's next page info
      };
    },
    getNextPageParam: (lastPage) => lastPage.nextPage || undefined,
    enabled: Boolean(searchedText?.length > 2 && searchZipCode),
  });

  // Mutation to add the pharmacy
  const addPharmacyMutation = useMutation({
    mutationFn: async (data) => {
      return addPharmacy(patientId, data);
    },
    onSuccess: () => {
      dispatch(getPharmacyList(patientId));
      queryClient.invalidateQueries({ queryKey: ["pharmacies", patientId] });
    },
  });

  // Mutation to delete the pharmacy
  const deletePharmacyMutation = useMutation({
    mutationFn: async (pharmacyId) => {
      return deletePharmacy(patientId, pharmacyId);
    },
    onSuccess: () => {
      dispatch(getPharmacyList(patientId));
      queryClient.invalidateQueries({ queryKey: ["pharmacies", patientId] });
    },
    onError: (error) => {
      increaseSkeletonLoaderCount();
      setApiError(
        error.response?.data?.detail || "Failed to delete the pharmacy.",
      );
    },
  });

  function increaseSkeletonLoaderCount() {
    setSkeletonLoaderCount((prev) => prev + 1);
  }

  // Debounce search input
  const handleSearch = debounce((value) => {
    let trimmedValue = value?.trim();
    if (trimmedValue?.length > 2) {
      setOptions([]);
      if (searchZipCode) {
        setSearchedText(trimmedValue);
      }
    } else {
      setSearchedText("");
    }
  }, 400);

  // Debounce zipcode changes (only when not using patient zip)
  const changeZipCode = debounce((e) => {
    setZipCode(e.target.value);
    setOptions([]);
  }, 500);

  const onPharmacySelect = (e, v) => {
    if (v?.label) {
      // setting the selected pharmacy in the dropdown options (for add button)
      setOptions((prev) =>
        prev.map((option) => {
          // To keep the selected pharmacy highlighted
          if (option.id === v?.id) {
            return {
              ...option,
              selected: true,
            };
          }

          // To remove the previous selected pharmacy
          if (selectedPharmacy?.dose_spot_id) {
            if (option.id === selectedPharmacy?.dose_spot_id) {
              return {
                ...option,
                selected: false,
              };
            }
          }
          return option;
        }),
      );

      // Set selected pharmacy for add button (this is separate from the radio-like behavior below)
      setSelectedPharmacy({
        pharmacy_name: v?.label,
        address: v?.value,
        phone: v?.phone,
        patient_id: patientId,
        dose_spot_id: v?.id,
      });
    }
  };

  // When a pharmacy in the added list is selected via its checkbox,
  // update the selectedPharmacyValue (and unselect any others)
  const onSelectAddedPharmacy = (pharmacy) => {
    setSelectedPharmacyValue(pharmacy);
  };

  const onDeletePharmacy = (pharmacy) => {
    if (setSelectedPharmacyValue) {
      if (pharmacy.dose_spot_id === selectedPharmacyValue?.dose_spot_id) {
        setSelectedPharmacyValue({});
      }
    }
    setSkeletonLoaderCount((prev) => prev - 1);
    deletePharmacyMutation.mutate(pharmacy?.id);
    setOptions((prev) =>
      prev.map((option) => {
        if (option.id === Number(pharmacy?.dose_spot_id)) {
          return {
            ...option,
            disabled: false,
          };
        }
        return option;
      }),
    );
  };

  function addSelectedPharmacy() {
    setSelectedPharmacyValue && setSelectedPharmacyValue(selectedPharmacy);
    increaseSkeletonLoaderCount();
    addPharmacyMutation.mutateAsync(selectedPharmacy);
    setShowAddPharmacy(false);
  }

  useEffect(() => {
    setAddedPharmacies(pharmacyList);
  }, [pharmacyList]);

  useEffect(() => {
    setSkeletonLoaderCount(addedPharmacies?.length);
  }, [addedPharmacies]);

  useEffect(() => {
    /**
     * Update the options with pharmacies from the current page.
     * If there are no pages, set options to an empty array.
     */
    const searchedPharmaciesOptions = data?.pages?.length
      ? data.pages[data.pages.length - 1]?.results || []
      : [];
    setOptions((prevState) => {
      if (addedPharmacies?.length) {
        const updatedOptions = searchedPharmaciesOptions.map((option) => {
          const isSelected = addedPharmacies?.some(
            (pharmacy) => Number(pharmacy?.dose_spot_id) === option?.id,
          );
          return {
            ...option,
            disabled: isSelected,
          };
        });
        return [...prevState, ...updatedOptions];
      } else {
        return [...prevState, ...searchedPharmaciesOptions];
      }
    });
  }, [data, addedPharmacies]);

  useEffect(() => {
    if (zipCode === null) setZipCode(getSpecificPatientData?.zipcode || "");
  }, [getSpecificPatientData]);

  let isLoading =
    addPharmacyMutation.isPending ||
    deletePharmacyMutation.isPending ||
    isPharmaciesFetching ||
    isPharmaciesLoading;

  return (
    <Box>
      {/* Top section: Checkbox for using the patient zipcode */}
      {showAddPharmacy && (
        <>
          <Box sx={{ marginBottom: 1, display: "flex", alignItems: "center" }}>
            <Box>
              <CheckBoxField
                labelPlacement="end"
                checked={usePatientZip}
                onChange={(e) => {
                  setUsePatientZip(e.target.checked);
                  if (e.target.checked) {
                    // If checked, set the zipcode to the patient's zipcode.
                    setZipCode(getSpecificPatientData?.zipcode || "");
                  } else {
                    // If unchecked, clear the zipcode field so user can enter a value.
                    setZipCode("");
                  }
                }}
              />
            </Box>
            <Text variant="bodyS" fontWeight={400}>
              Use Patient Zip Code {getSpecificPatientData?.zipcode ?? ""}
            </Text>
          </Box>
          <Grid container spacing={2}>
            <SelectField
              cols={usePatientZip ? 11 : 9}
              onSearch={handleSearch}
              onChange={onPharmacySelect}
              options={options}
              disabled={isLoading}
              freeSolo={true}
              enableFiltering={false}
              loading={isSearchedPharmaciesLoading}
              placeholder="Search pharmacy"
              showMoreInLabel={{ show: true, value: "value" }}
              infiniteScrollDetails={{
                hasMoreToFetch: searchedText?.length > 2 ? !!hasNextPage : null,
                fetchMore: () => fetchNextPage(),
              }}
            />
            {!usePatientZip && (
              <InputField
                cols={2}
                validators={[Required]}
                placeholder={"Zip Code"}
                onChange={changeZipCode}
                value={zipCode}
                disabled={isLoading}
              />
            )}
            <Grid item sx={1}>
              <Box
                onClick={() => addSelectedPharmacy()}
                sx={{
                  cursor: "pointer",
                  fontSize: "18px",
                  borderRadius: "10px",
                  fontWeight: 700,
                  color: theme.palette.common.blue,
                  padding: "5px 12px",
                  border: `1px solid ${theme.palette.common.grey}`,
                }}>
                +
              </Box>
            </Grid>
          </Grid>
        </>
      )}
      <Box paddingY={2}>
        <SkeletonWrapper
          condition={!isLoading}
          multipleCount={skeletonLoaderCount}
          props={[SkeletonPropsStyleGenerator("100%", "44px", "150px")]}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Text fontWeight={600} variant="bodyS">
                Pharmacy Name
              </Text>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Text fontWeight={600} variant="bodyS">
                Address
              </Text>
            </Grid>
          </Grid>
          <Box sx={{ maxHeight: "300px", overflowY: "auto", marginTop: 2 }}>
            {addedPharmacies?.length ? (
              addedPharmacies.map((pharmacy, index) => (
                <Grid
                  container
                  spacing={2}
                  paddingY={1.5}
                  key={index}
                  borderBottom={"1px solid #ccc"}>
                  <Grid item xs={12} sm={3} sx={{ display: "flex", gap: 1 }}>
                    <Box>
                      <CheckBoxField
                        labelPlacement="end"
                        // This checkbox now behaves like a radio button:
                        // It is checked only if the pharmacy's dose_spot_id matches
                        // the one in selectedPharmacyValue.
                        checked={
                          selectedPharmacyValue?.dose_spot_id ===
                          pharmacy?.dose_spot_id
                        }
                        onChange={() => onSelectAddedPharmacy(pharmacy)}
                      />
                    </Box>
                    <Text variant="bodyS">{pharmacy?.pharmacy_name}</Text>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Text variant="bodyS">{pharmacy?.address}</Text>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Box
                        onClick={() => onDeletePharmacy(pharmacy)}
                        sx={{ cursor: "pointer" }}>
                        <Trash />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Grid
                container
                spacing={2}
                paddingY={1.5}
                borderBottom={"1px solid #ccc"}>
                <Grid item xs={12} sm={12} textAlign={"center"}>
                  <Text center variant="bodyS">
                    No Pharmacies Found
                  </Text>
                </Grid>
              </Grid>
            )}
          </Box>
          {!showAddPharmacy && (
            <Box>
              <Button
                sx={{ marginTop: 1 }}
                variant="text"
                text={"+ Add Pharmacy"}
                onClick={() => setShowAddPharmacy(true)}
              />
            </Box>
          )}
          {apiError.length > 0 && (
            <Alert
              sx={{ marginTop: "5px" }}
              severity="error"
              onClose={() => setApiError("")}>
              {apiError}
            </Alert>
          )}
        </SkeletonWrapper>
      </Box>
    </Box>
  );
};

export default memo(PharmacyModal);
