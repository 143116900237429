import { Checkbox, FormControlLabel, Grid, useTheme } from "@mui/material";
import Text from "../Typography/Text";
import { makeStyles } from "@mui/styles";
import { Widgets } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .css-tzssek-MuiSvgIcon-root": {
      color: theme.palette.primary.main,
      width: "24px",
      height: "24px",
    },
  },
}));

const CheckBoxField = ({
  label,
  checkLabel,
  name,
  checked = false,
  onChange,
  labelPlacement = "start",
  cols = 6,
  required = false,
  formik,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const handleChange = (event) => {
    let value;
    if (onChange) {
      onChange(event);
    } else {
      value = event.target.checked;
      formik && formik.setFieldValue(name, value);
    }
  };

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
      item
      xs={12}
      sm={cols}>
      {label && <Text variant="body2">{label}</Text>}
      <FormControlLabel
        sx={{
          gap: 1,
          "$ .MuiFormControlLabel-label": {
            fontSize: "15px",
          },
        }}
        control={
          <Checkbox
            className={classes.root}
            id={name}
            name={name}
            size="large"
            checked={checked ?? undefined}
            value={checked ?? formik?.values[name] ?? false}
            onChange={handleChange}
          />
        }
        label={checkLabel ? `${checkLabel} ${required ? "*" : ""}` : ""}
        labelPlacement={labelPlacement}
      />
    </Grid>
  );
};

export default CheckBoxField;
