// These tabs are for graph tabs in trends sections (for graphs)
import { Box, Checkbox, Tab, useTheme } from "@mui/material";
import { useState } from "react";
import { trendsVitalsButtonsStyle } from "styles/SCD/trendsStyle";

const CustomTabs = ({ label, colors, defaultChecked, setActiveGraphs }) => {
  let theme = useTheme();
  const [isChecked, setIsChecked] = useState(defaultChecked);

  function handleChange(checked, label) {
    checked ? setIsChecked(true) : setIsChecked(false);
    setActiveGraphs(checked, label);
  }

  const getDisplayLabel = (label) => {
    switch (label) {
      case "BP":
        return "Blood Pressure";
      case "Temp":
        return "Temperature";
      case "HR":
        return "Heart Rate";
      case "RR":
        return "Respiration Rate";
      case "WT":
        return "Weight";
      case "Spo2":
        return "Oxygen Saturation";
      default:
        return label;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        marginTop: "10px",
        marginBottom: "10px",
        flexDirection: "row",
        alignItems: "center",
      }}>
      <Checkbox
        sx={{
          height: "0.875rem",
          width: "0.875rem",
          margin: "2px",
          "& .MuiSvgIcon-root": {
            color: isChecked ? "#9021FF" : "",
            fontSize: "0.875rem",
          },
          "& .MuiSvgIcon-root > path": {
            color: isChecked ? "" : theme.palette.text.secondary,
          },
        }}
        defaultChecked={defaultChecked}
        onChange={(e) => handleChange(e?.target?.checked, label)}
      />
      <Tab
        label={getDisplayLabel(label)}
        sx={trendsVitalsButtonsStyle(label, theme)}
      />
      <div
        style={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}>
        {colors?.map((c) => (
          <div
            style={{
              backgroundColor: c,
              width: "10px",
              height: "10px",
              margin: "3px",
              borderRadius: "100%",
            }}>
            {" "}
          </div>
        ))}
      </div>
    </Box>
  );
};

export default CustomTabs;
