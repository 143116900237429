import { Box, Stack } from "@mui/material";
import Text from "components/common/Typography/Text";
import { flexRowCenter } from "styles";

const VitalDetailCard = ({
  vitalData,
  active = false,
  marginTop,
  pastVisit,
}) => {
  return (
    <Box sx={{ marginTop: marginTop }}>
      {vitalData != null ? (
        <>
          {!pastVisit ? (
            <>
              <Box
                sx={{
                  color: "#2D3748",
                  fontSize: "20px",
                  lineHeight: "28.79px",
                  fontWeight: "700",
                }}>
                <span style={{ borderBottom: "2px solid  #1344F1" }}>
                  Vital Results
                </span>
              </Box>
            </>
          ) : (
            ""
          )}
          <Box
            sx={{
              padding: "15px",
              borderRadius: "10px",
              marginTop: !pastVisit ? "" : "20px",
            }}>
            <Box sx={{ maxHeight: "490px", overflowY: "auto" }}>
              <Stack direction="column" spacing={2}>
                <Box sx={{ ...flexRowCenter }}>
                  <Text
                    variant="h3"
                    data-testid="scd-vitalDetails-label-temp"
                    minWidth="200px">
                    Temperature:
                  </Text>
                  {vitalData?.temperature && (
                    <Text
                      variant="bodyXs"
                      data-testid="scd-vitalDetails-value-temp"
                      marginLeft="20px">
                      {vitalData?.temperature} °F{" "}
                      {vitalData?.temperature &&
                        `(${Math.round(vitalData?.temperature - (32 * 5) / 9)} °C )`}
                    </Text>
                  )}
                </Box>
                <Box sx={{ ...flexRowCenter }}>
                  <Text
                    variant="h3"
                    data-testid="scd-vitalDetails-label-BP"
                    minWidth="200px">
                    Blood Pressure:
                  </Text>
                  <Text
                    variant="bodyXs"
                    data-testid="scd-vitalDetails-value-BP"
                    marginLeft="20px">
                    {vitalData?.systolic} / {vitalData?.diastolic} mm Hg
                  </Text>
                </Box>
                <Box sx={{ ...flexRowCenter }}>
                  <Text
                    variant="h3"
                    data-testid="scd-vitalDetails-label-HR"
                    minWidth="200px">
                    Heart Rate:
                  </Text>
                  <Text
                    variant="bodyXs"
                    data-testid="scd-vitalDetails-value-HR"
                    marginLeft="20px">
                    {vitalData?.pulse} beats per minute
                  </Text>
                </Box>
                <Box sx={{ ...flexRowCenter }}>
                  <Text
                    variant="h3"
                    data-testid="scd-vitalDetails-label-RR"
                    minWidth="200px">
                    Respiratory Rate:
                  </Text>
                  <Text
                    variant="bodyXs"
                    data-testid="scd-vitalDetails-value-RR"
                    marginLeft="20px">
                    {vitalData?.respiration_rate} breaths per minute
                  </Text>
                </Box>
              </Stack>
              <Stack direction="column" spacing={2} marginTop={"17px"}>
                <Box sx={{ ...flexRowCenter }}>
                  <Text
                    variant="h3"
                    data-testid="scd-vitalDetails-label-bw"
                    minWidth="200px">
                    Body Weight:
                  </Text>
                  <Text
                    variant="bodyXs"
                    data-testid="scd-vitalDetails-value-bw"
                    marginLeft="20px">
                    {vitalData?.weight} lbs ({" "}
                    {Math.round(vitalData?.weight * 0.45359237)} Kg )
                  </Text>
                </Box>
              </Stack>
              <Stack direction="column" spacing={2} marginTop={"17px"}>
                <Box sx={{ ...flexRowCenter }}>
                  <Text
                    variant="h3"
                    data-testid="scd-vitalDetails-label-spo2"
                    minWidth="200px">
                    Oxygen Saturation
                  </Text>
                  <Text
                    variant="bodyXs"
                    data-testid="scd-vitalDetails-value-spo2"
                    marginLeft="20px">
                    {vitalData?.spo2} %
                  </Text>
                </Box>
                <Box sx={{ ...flexRowCenter }}>
                  <Text
                    variant="h3"
                    data-testid="scd-vitalDetails-label-uo"
                    minWidth="200px">
                    Urine Output (last 24hr):
                  </Text>
                  <Text
                    variant="bodyXs"
                    data-testid="scd-vitalDetails-value-uo"
                    marginLeft="20px">
                    {vitalData?.urine_output} mL
                  </Text>
                </Box>
                <Box sx={{ ...flexRowCenter }}>
                  <Text
                    variant="h3"
                    data-testid="scd-vitalDetails-label-pain"
                    minWidth="200px">
                    Pain Assessment:
                  </Text>
                  <Text
                    variant="bodyXs"
                    data-testid="scd-vitalDetails-value-pain"
                    marginLeft="20px">
                    {vitalData?.pain_assessment} (Scale of{" "}
                    {vitalData?.pain_assessment} to 10, 10 being worst pain)
                  </Text>
                </Box>
                <Box sx={{ ...flexRowCenter }}>
                  <Text
                    variant="h3"
                    data-testid="scd-vitalDetails-label-edema"
                    minWidth="200px">
                    Edema Assessment:
                  </Text>
                  <Text
                    variant="bodyXs"
                    data-testid="scd-vitalDetails-value-edema"
                    marginLeft="20px">
                    {vitalData?.edema_assessment}
                  </Text>
                </Box>
                <Box sx={{ ...flexRowCenter }}>
                  <Text
                    variant="h3"
                    data-testid="scd-vitalDetails-label-others"
                    minWidth="200px">
                    Other Observations:
                  </Text>
                  <Text
                    variant="bodyXs"
                    data-testid="scd-vitalDetails-value-others"
                    marginLeft="20px">
                    {vitalData?.other_observations}
                  </Text>
                </Box>
              </Stack>
            </Box>
          </Box>
        </>
      ) : (
        <Box marginY={4}>
          <Text variant="bodyS">No Data Found.</Text>
        </Box>
      )}
    </Box>
  );
};

export default VitalDetailCard;
