import { Box, Grid } from "@mui/material";
import Text from "components/common/Typography/Text";
import { useEffect, useState } from "react";
import { checkNullOrUndefinedString } from "utils/helper";
import { getSortedFormattedLabs } from "utils/labs/utils";
import { ArrowUp } from "components/common/SVG/ArrowUp";
import { ArrowDown } from "components/common/SVG/ArrowDown";
import { useTheme } from "@mui/material/styles";
import CustomTooltip from "components/common/Tooltips/CustomTooltip";

const LabResultDetailCard = ({ data, marginTop, pastVisit }) => {
  const [sortedFormattedLabs, setSortedFormattedLabs] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    setSortedFormattedLabs(
      getSortedFormattedLabs(data)?.sortedLabsObservations,
    );
  }, [data]);

  return (
    <Box
      sx={{
        marginTop: marginTop,
        minHeight: "490px",
        height: "490px",
        flexShrink: 0,
      }}>
      {data !== null ? (
        <>
          <Box
            sx={{
              borderRadius: "10px",
              marginTop: "10px",
              height: "100%",
            }}>
            <Box
              data-testid="labresults-tests-details"
              sx={{
                height: "100%",
                overflowY: "auto",
                marginTop: "10px",
              }}>
              <Box
                sx={{
                  minHeight: "100%",
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "100%",
                    xl: "70%",
                  },
                }}>
                <Grid container spacing={1}>
                  {sortedFormattedLabs?.map(
                    ({ code, val, at_risk, range, isUp }, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        lg={6}
                        xl={6}
                        xxl={4}
                        key={`item-${index}`}>
                        <Box
                          sx={{
                            marginLeft: "10px",
                          }}>
                          <Box marginY={1}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                flex: 1,
                              }}>
                              <Text
                                data-testid={`lab-test-name-${index}`}
                                variant="h3"
                                sx={{
                                  wordBreak: "break-word",
                                  whiteSpace: "normal",
                                  flex: 1,
                                  fontWeight: "700",
                                  minWidth: "70px",
                                }}>
                                {code}:
                              </Text>
                              {checkNullOrUndefinedString(range) ? (
                                <CustomTooltip
                                  tooltipText={
                                    <Box sx={{ padding: "5px" }}>
                                      <Box>
                                        <Text
                                          variant="h3"
                                          sx={{ fontWeight: 500 }}>
                                          <strong>
                                            {code} Reference Range
                                          </strong>
                                        </Text>
                                      </Box>
                                      <Box sx={{ marginTop: "5px" }}>
                                        <Text variant="formLabel">
                                          {checkNullOrUndefinedString(range)}
                                        </Text>
                                      </Box>
                                    </Box>
                                  }
                                  arrow>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}>
                                    <Text
                                      data-testid={`lab-value-${index}`}
                                      variant="bodyS"
                                      marginLeft="10px"
                                      marginRight="10px"
                                      color={
                                        at_risk === "at_risk" ? "redLight" : ""
                                      }
                                      sx={{ cursor: "context-menu" }}>
                                      {checkNullOrUndefinedString(
                                        Array.isArray(val) && val.length > 1
                                          ? val.join("-")
                                          : val,
                                      )}
                                    </Text>
                                    {at_risk === "at_risk" ? (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          padding: "5px",
                                          borderRadius: "10px",
                                          justifyContent: "center",
                                          background: theme.palette.error.light,
                                        }}>
                                        {isUp ? <ArrowUp /> : <ArrowDown />}
                                      </Box>
                                    ) : null}
                                  </Box>
                                </CustomTooltip>
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}>
                                  <Text
                                    data-testid={`lab-value-${index}`}
                                    variant="bodyS"
                                    marginLeft="10px"
                                    marginRight="10px"
                                    color={
                                      at_risk === "at_risk" ? "redLight" : ""
                                    }>
                                    {checkNullOrUndefinedString(
                                      Array.isArray(val) && val.length > 1
                                        ? val.join("-")
                                        : val,
                                    )}
                                  </Text>
                                  {at_risk === "at_risk" ? (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        borderRadius: "10px",
                                        justifyContent: "center",
                                        background: theme.palette.error.light,
                                      }}>
                                      {isUp ? <ArrowUp /> : <ArrowDown />}
                                    </Box>
                                  ) : null}
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    ),
                  )}
                </Grid>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box marginY={4}>
          <Text variant="bodyS">No Data Found.</Text>
        </Box>
      )}
    </Box>
  );
};

export default LabResultDetailCard;
