import { Box } from "@mui/material";
import Button from "components/common/Button/Button";
import InputField from "components/common/FormComponents/InputField";
import Text from "components/common/Typography/Text";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import CustomModal from "components/common/Modal/CustomModal";
import { setOpenDosepotConnection } from "redux/features/globalStateSlice";
import { useMutation, useQuery } from "@tanstack/react-query";
import { DoseSpotLogo } from "assets/svg/colored/dosepot-logo";
import {
  addCredentials,
  fetchCredentials,
  updateCredentials,
} from "apiClients/emrCredentials";

const DosespotConnectionSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
});

const DosespotConnectionModal = ({ open, onClose }) => {
  const dispatch = useDispatch();

  // Fetch existing credentials
  const { data: existingCredentials } = useQuery({
    queryKey: ["credentials"],
    queryFn: fetchCredentials,
  });

  // Find if dosespot credentials already exist
  const existingDosespotCred = existingCredentials?.find(
    (cred) => cred.plugin_name === "dosespot",
  );

  const mutation = useMutation({
    mutationFn: async (values) => {
      if (existingDosespotCred) {
        // Update existing credentials
        return await updateCredentials(existingDosespotCred.id, {
          ...values,
          plugin_name: "dosespot",
        });
      } else {
        // Add new credentials
        return await addCredentials({
          ...values,
          plugin_name: "dosespot",
        });
      }
    },
    onSuccess: () => {
      toast.success("dosespot connection established successfully");
      dispatch(setOpenDosepotConnection(false));
      onClose();
    },
    onError: (error) => {
      toast.error(
        error?.response?.data?.message || "Failed to connect to dosespot",
      );
    },
  });

  const formik = useFormik({
    initialValues: {
      username: existingDosespotCred?.username || "",
      password: "",
    },
    validationSchema: DosespotConnectionSchema,
    onSubmit: (values) => {
      mutation.mutate(values);
    },
  });

  const handleClose = () => {
    onClose();
    formik.resetForm();
    mutation.reset();
  };

  return (
    <CustomModal
      title={"Resolve Connection"}
      open={open}
      setOpen={handleClose}
      maxWidth="sm"
      fullWidth>
      <Box sx={{ padding: "24px" }}>
        <DoseSpotLogo />
        <Box sx={{ marginBottom: 4, marginTop: 2 }}>
          <Text sx={{ whiteSpace: "unset" }} variant="h4">
            To connect dosespot to your Nephrolytics account, enter the login
            credentials sent by dosespot via email.
          </Text>
        </Box>
        <Box sx={{ marginBottom: 4 }}>
          <Box sx={{ marginBottom: 3 }}>
            <InputField
              required
              formik={formik}
              label="Username"
              name="username"
              placeholder="Enter Username"
              fullWidth
            />
          </Box>
          <Box sx={{ marginBottom: 3 }}>
            <InputField
              required
              formik={formik}
              label="Password"
              name="password"
              type="password"
              placeholder="Enter Password"
              fullWidth
            />
            {mutation.error && (
              <Text marginY={1} variant="body2" color="red">
                {mutation.error?.response?.data?.message}
              </Text>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            marginTop: "20px",
          }}>
          <Button
            text="Cancel"
            variant="outlined"
            color="tertiary"
            onClick={handleClose}
          />
          <Button
            text={existingDosespotCred ? "Update" : "Login"}
            isLoading={mutation.isLoading}
            onClick={formik.handleSubmit}
          />
        </Box>
      </Box>
    </CustomModal>
  );
};

export default DosespotConnectionModal;
