import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isfirstLogin: true,
  navigationPathisRecording: false,
  currentNavigationPath: null,
  openDosepotConnection: false,
};

const name = "globalStates";

export const globalSlice = createSlice({
  name,
  initialState,
  reducers: {
    setFirstLogin: (state) => {
      state.isfirstLogin = false;
    },
    setRecording: (state, { payload }) => {
      state.isRecording = payload;
    },
    setOpenDosepotConnection: (state, { payload }) => {
      state.openDosepotConnection = payload;
    },
    setCurrentNavigationPath: (state, { payload }) => {
      state.currentNavigationPath = payload;
    },
  },
});

export const {
  setFirstLogin,
  setCurrentNavigationPath,
  setOpenDosepotConnection,
  setRecording,
} = globalSlice.actions;

export default globalSlice.reducer;
