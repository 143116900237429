import { useFormik } from "formik";
import * as toast from "hooks/notify";
import { flexRowCenter } from "styles";
import LoginWrapper from "./LoginWrapper";
import { Box, Grid } from "@mui/material";
import { useTimer } from "react-timer-hook";
import email from "assets/svg/light/email.svg";
import { useEffect, useState } from "react";
import Button from "components/common/Button/Button";
import Text from "components/common/Typography/Text";
import { useDispatch, useSelector } from "react-redux";
import { getCSRF } from "redux/features/Auth/creatNewPasswordSlice";
import InputField from "components/common/FormComponents/InputField";
import { forgotPasswordValidationSchema } from "validations/auth/login/forgotPassword.schema";
import {
  clearRequestForgotPassword,
  userRequestForgotPassword,
} from "redux/features/Auth/forgotPasswordSlice";

const ForgotPassword = () => {
  const dispatch = useDispatch();

  // To change the text of button once email is sent
  const [emailSentCount, setEmailSentCount] = useState(0);

  const { forgotPasswordSuccess, forgotPasswordIsLoading } = useSelector(
    (state) => state.forgotPassword,
  );

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordValidationSchema,
    onSubmit: (value) => {
      setEmailSentCount(emailSentCount + 1);
      dispatch(userRequestForgotPassword(value));
    },
  });

  useEffect(() => {
    dispatch(getCSRF());
  }, []);

  useEffect(() => {
    if (forgotPasswordSuccess === true) {
      toast.success("Email has been sent");

      // Dispatching right after sent (reason) => clear state for the next resend
      dispatch(clearRequestForgotPassword());
    }
    if (forgotPasswordSuccess === false) {
      toast.error("User not found");
      dispatch(clearRequestForgotPassword());
    }
  }, [forgotPasswordSuccess]);

  useEffect(() => {
    return () => {
      dispatch(clearRequestForgotPassword());
    };
  }, []);

  return (
    <LoginWrapper>
      <Box
        sx={{
          maxWidth: "400px",
          marginTop: "50px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Text id={"password-heading"} center marginY={2} variant="h1">
              Forgot Password?
            </Text>
          </Box>
          <Box sx={{ ...flexRowCenter, textAlign: "center" }}>
            <Box sx={{ maxWidth: "355px" }}>
              <Text
                id={"password-validation-msg"}
                center
                variant="bodyS"
                marginY={2}
                sx={{
                  display: "flex",
                  whiteSpace: "normal",
                  lineHeight: "1.5",
                  textAlign: "center",
                }}>
                Please enter the email address associated with your account to
                reset your password
              </Text>
            </Box>
          </Box>
        </Box>
        <Box sx={{ maxWidth: "300px", width: "100%" }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
              <InputField
                xs={12}
                sm={12}
                md={12}
                lg={12}
                name="email"
                positionLeft
                type={"email"}
                formik={formik}
                placeholder={"E-mail Address"}
                disabled={forgotPasswordIsLoading}
                iconDetails={{ allowIcon: true, iconName: email }}
              />
            </Grid>
            <Button
              id="save-password-btn"
              type="submit"
              text={emailSentCount > 0 ? "Resend" : "Next"}
              isLoading={forgotPasswordIsLoading}
              sx={{
                width: "100%",
                height: "38px",
                marginTop: "10px",
                "&:hover": {
                  color: "#fff",
                },
                backgroundImage:
                  "linear-gradient(270deg, #4C2631 1.99%, #285677 90.93%)",
                border: "none",
              }}
            />
          </form>
        </Box>
      </Box>
    </LoginWrapper>
  );
};

export default ForgotPassword;
