import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Text from "components/common/Typography/Text";
import { useNotesHistoryModalStyles } from "styles/Modals/ViewNotesHistoryModalStyle";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { clearAddUpdateDeleteMedicationsData } from "redux/features/Medications/MedicationsSlice";
import { useParams } from "react-router-dom";
import Accordian from "./Accordian/MedicationsAccordian";
import { useQueryClient } from "@tanstack/react-query";

const MedicationDetail = ({ activeMedications, inActiveMedications }) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const classes = useNotesHistoryModalStyles();
  const { patientId } = useParams();
  const { deletedMedicationSuccessfully, updatedMedicationSuccessfully } =
    useSelector((state) => state.medications);

  useEffect(() => {
    if (deletedMedicationSuccessfully) {
      toast.success("Medication deleted");
      dispatch(clearAddUpdateDeleteMedicationsData());
    }
  }, [deletedMedicationSuccessfully]);

  useEffect(() => {
    if (updatedMedicationSuccessfully) {
      queryClient.invalidateQueries({ queryKey: ["medications", patientId] });
      dispatch(clearAddUpdateDeleteMedicationsData());
    }
  }, [updatedMedicationSuccessfully]);

  return (
    <Box>
      {activeMedications?.length > 0 ? (
        activeMedications?.map((ele, i) => (
          <Accordian
            key={i}
            data={ele}
            isActive={true}
            classes={classes}
            id={ele?.id}
            currentMed={ele}
          />
        ))
      ) : (
        <Box>No Data Found</Box>
      )}
      <Box marginTop={3}>
        <Text variant="h2" fontWeight="600">
          <span data-testid="inactive-medication-detail-heading">Inactive</span>
        </Text>
        {inActiveMedications?.length > 0 ? (
          inActiveMedications?.map((ele, i) => (
            <Accordian
              key={i}
              data={ele}
              classes={classes}
              id={ele?.id}
              isActive={false}
            />
          ))
        ) : (
          <div>No Data Found</div>
        )}
      </Box>
    </Box>
  );
};

export default MedicationDetail;
