import { memo, useContext, useEffect, useState } from "react";
import { SubForm } from "../common/SubForm";
import Text from "components/common/Typography/Text";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { IntakeFormContext } from "../common/IntakeFormProvider";
import {
  addMedicalReport,
  deleteMedicalReport,
  fetchAllMedicalReports,
  updateMedicalReport,
} from "apiClients/medicalReports";
import { Required } from "validations/validators";
import moment from "moment";
import { dateFormat } from "utils/date";
import { formatErrorsToAlerts } from "../common/utils";

const ReportTypes = Object.freeze({
  BLOOD_WORK: "Blood Work",
  URINE_ANALYSIS: "Urine Analysis",
  CT_SCAN: "CT Scan",
  MRI: "MRI",
  ULTRASOUND: "Ultrasound",
  DEXA_SCAN: "DEXA Scan",
  COLONOSCOPY: "Colonoscopy",
  ECHOCARDIOGRAM: "Echocardiogram",
  CARDIAC_CATHETERIZATION: "Cardiac Catheterization",
  ORGAN_TRANSPLANT: "Organ Transplant",
});

const inputs = [
  {
    label: "Type",
    key: "report_type",
    type: "select",
    cols: 2,
    options: Object.values(ReportTypes).map((value) => ({
      value,
      label: value,
    })),
    validators: [Required],
  },
  {
    label: "Provider",
    key: "name",
    placeholder: "Provider Name",
    cols: 3,
  },
  {
    label: "Location",
    key: "location",
    cols: 2,
  },
  {
    label: "Reason",
    key: "reason",
    placeholder: "Reason For Order",
    cols: 3,
  },
  {
    label: "Date",
    key: "date",
    type: "date",
    cols: 2,
    max: moment().format(dateFormat),
    validators: [Required],
  },
];

export const LabsReview = memo(({ errors }) => {
  const queryClient = useQueryClient();
  const [values, setValues] = useState({
    bloodWork: [],
    urineAnalysis: [],
    ctScan: [],
    mri: [],
    ultrasound: [],
    dexaScan: [],
    colonoscopy: [],
    echocardiogram: [],
    cardiacCatheterization: [],
    organTransplant: [],
  });
  const { registerForm, patientId } = useContext(IntakeFormContext);

  const mutation = useMutation({
    mutationFn: async (dirtyValue) => {
      if (dirtyValue.id) {
        if (dirtyValue.delete) {
          // If delete is set we need to delete the medical report.
          return deleteMedicalReport(patientId, dirtyValue.id);
        } else {
          // If no delete is set, we need to update the medical report since it is dirty (changed).
          return updateMedicalReport(patientId, dirtyValue.id, dirtyValue);
        }
      } else {
        // If no ID is present, it is a new medical report and we need to add it.
        return addMedicalReport(patientId, dirtyValue);
      }
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["medicalReports", patientId],
      });
    },
  });

  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: ["medicalReports", patientId],
      });
    };
  }, [queryClient, patientId]);

  const { data: { results } = {}, isLoading } = useQuery({
    queryKey: ["medicalReports", patientId],
    queryFn: () => fetchAllMedicalReports(patientId),
  });

  const handleSubmit = (dirtyValues) => {
    const operations = dirtyValues.map((dirtyValue) => {
      const formattedValue = {
        ...dirtyValue,
        date: dirtyValue.date
          ? moment(dirtyValue.date).format("YYYY-MM-DD")
          : null,
      };

      return mutation.mutateAsync(formattedValue);
    });

    return Promise.allSettled(operations);
  };

  useEffect(() => {
    if (results) {
      setValues(results);
      registerForm("medicalReports", handleSubmit, results);
    }
  }, [results]);

  return (
    <>
      <Text variant="h1">Labs, Imaging, & Docs</Text>
      <SubForm
        dataLoading={isLoading || mutation.isLoading}
        formKey={"medicalReports"}
        subSections={inputs}
        data={values}
      />
      {formatErrorsToAlerts(errors)}
    </>
  );
});
