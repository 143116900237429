import { Box } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Form } from "components/common/FormComponents/Form";
import InputField from "components/common/FormComponents/InputField";
import CustomModal from "components/common/Modal/CustomModal";
import Text from "components/common/Typography/Text";
import Button from "components/common/Button/Button";
import { useFeedBackMutation } from "hooks/useFeedback";
import * as toast from "hooks/notify";
import { reportAnIssueSchema } from "./reportAnIssue.schema";
import { useFormik } from "formik";

const ReportAnIssueModal = ({ isOpen, onClose }) => {
  const { patientId } = useParams();
  const { mutationFeedback } = useFeedBackMutation();
  const { userId } = useSelector((state) => state.auth);

  const [error, setError] = useState(null);

  const handleCloseReportIssue = () => {
    onClose(false);
  };

  const handleSubmit = async (data) => {
    try {
      await mutationFeedback.mutateAsync({
        ...data,
        item_for_feedback: "generic",
        user_id: userId,
        item_id: patientId ?? "",
      });
      toast.success("Thank you for your feedback");
      handleCloseReportIssue();
    } catch {
      setError("Failed to report issue. Please try again.");
    }
  };

  const formik = useFormik({
    initialValues: {
      feedback: "",
    },
    validationSchema: reportAnIssueSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <CustomModal
      setOpen={handleCloseReportIssue}
      open={isOpen}
      title="Report An Issue"
      maxWidth="sm">
      <Box mt={3}>
        <Box width="481px">
          <Box mb={2}>
            <Text variant="bodyS" sx={{ whiteSpace: "normal" }}>
              Leave a comment below to report an issue with the Nephrolytics
              application. Our support team will work to resolve the issue.
            </Text>
          </Box>
          <Text variant="bodyS" fontWeight={500}>
            Describe The Issue
          </Text>

          <InputField
            name="feedback"
            placeholder="Enter Comment"
            rows={4}
            multiline
            formik={formik}
            sxInputProps={{ fontSize: "14px" }}
            data-testid="input-feedback"
          />

          <Box
            width="493px"
            display="flex"
            justifyContent="flex-end"
            gap={2}
            pb={0}
            mt={6}>
            <Button
              variant="outlined"
              id="cancel-button"
              text="Cancel"
              onClick={handleCloseReportIssue}
            />
            <Button
              id="submit-button"
              disabled={mutationFeedback.isPending}
              text="Submit"
              onClick={formik.handleSubmit}
              data-testid="submit-button"
            />
          </Box>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default ReportAnIssueModal;
