export const Refresh = ({ fill = "#246EFD" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_5429_24792"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5429_24792)">
        <path
          d="M8.18742 17.25C6.75686 16.8472 5.5902 16.059 4.68742 14.8854C3.78464 13.7118 3.33325 12.3611 3.33325 10.8333C3.33325 10.0417 3.4652 9.2882 3.72909 8.57292C3.99297 7.85764 4.36797 7.20139 4.85409 6.60417C5.00686 6.4375 5.19436 6.3507 5.41659 6.34375C5.63881 6.33681 5.8402 6.42361 6.02075 6.60417C6.17353 6.75695 6.25339 6.94445 6.26034 7.16667C6.26728 7.38889 6.19436 7.59722 6.04159 7.79167C5.70825 8.22222 5.45131 8.69445 5.27075 9.20833C5.0902 9.72222 4.99992 10.2639 4.99992 10.8333C4.99992 11.9583 5.32978 12.9618 5.9895 13.8438C6.64922 14.7257 7.49992 15.3264 8.54158 15.6458C8.72214 15.7014 8.87145 15.8056 8.9895 15.9583C9.10756 16.1111 9.16658 16.2778 9.16658 16.4583C9.16658 16.7361 9.06936 16.9549 8.87492 17.1146C8.68047 17.2743 8.45131 17.3194 8.18742 17.25ZM11.8124 17.25C11.5485 17.3194 11.3194 17.2708 11.1249 17.1042C10.9305 16.9375 10.8333 16.7153 10.8333 16.4375C10.8333 16.2708 10.8923 16.1111 11.0103 15.9583C11.1284 15.8056 11.2777 15.7014 11.4583 15.6458C12.4999 15.3125 13.3506 14.7083 14.0103 13.8333C14.6701 12.9583 14.9999 11.9583 14.9999 10.8333C14.9999 9.44445 14.5138 8.26389 13.5416 7.29167C12.5694 6.31944 11.3888 5.83333 9.99992 5.83333H9.93742L10.2708 6.16667C10.4235 6.31944 10.4999 6.51389 10.4999 6.75C10.4999 6.98611 10.4235 7.18056 10.2708 7.33333C10.118 7.48611 9.92353 7.5625 9.68742 7.5625C9.45131 7.5625 9.25686 7.48611 9.10409 7.33333L7.35409 5.58333C7.27075 5.5 7.21172 5.40972 7.177 5.3125C7.14228 5.21528 7.12492 5.11111 7.12492 5C7.12492 4.88889 7.14228 4.78472 7.177 4.6875C7.21172 4.59028 7.27075 4.5 7.35409 4.41667L9.10409 2.66667C9.25686 2.51389 9.45131 2.4375 9.68742 2.4375C9.92353 2.4375 10.118 2.51389 10.2708 2.66667C10.4235 2.81944 10.4999 3.01389 10.4999 3.25C10.4999 3.48611 10.4235 3.68056 10.2708 3.83333L9.93742 4.16667H9.99992C11.861 4.16667 13.4374 4.8125 14.7291 6.10417C16.0208 7.39583 16.6666 8.97222 16.6666 10.8333C16.6666 12.3472 16.2152 13.6944 15.3124 14.875C14.4096 16.0556 13.243 16.8472 11.8124 17.25Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
