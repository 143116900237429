const editorTheme = {
  code: "editor-code",
  heading: {
    h1: "editor-heading-h1",
    h2: "editor-heading-h2",
    h3: "editor-heading-h3",
    h4: "editor-heading-h4",
    h5: "editor-heading-h5",
  },
  image: "editor-image",
  link: "editor-link",
  list: {
    listitem: "editor-listitem",
    nested: {
      listitem: "editor-nested-listitem",
    },
    ol: "editor-list-ol",
    ul: "editor-list-ul",
  },
  ltr: "ltr",
  paragraph: "editor-paragraph",
  placeholder: "editor-placeholder",
  quote: "editor-quote",
  rtl: "rtl",
  table: "EditorTheme__table",
  tableCell: "EditorTheme__tableCell",
  tableCellActionButton: "EditorTheme__tableCellActionButton",
  tableCellActionButtonContainer: "EditorTheme__tableCellActionButtonContainer",
  tableCellEditing: "EditorTheme__tableCellEditing",
  tableCellHeader: "EditorTheme__tableCellHeader",
  tableCellPrimarySelected: "EditorTheme__tableCellPrimarySelected",
  tableCellResizer: "EditorTheme__tableCellResizer",
  tableCellSelected: "EditorTheme__tableCellSelected",
  tableCellSortedIndicator: "EditorTheme__tableCellSortedIndicator",
  tableResizeRuler: "EditorTheme__tableCellResizeRuler",
  tableSelected: "EditorTheme__tableSelected",
  tableSelection: "EditorTheme__tableSelection",
  text: {
    bold: "editor-text-bold",
    code: "editor-text-code",
    hashtag: "editor-text-hashtag",
    italic: "editor-text-italic",
    overflowed: "editor-text-overflowed",
    strikethrough: "editor-text-strikethrough",
    underline: "editor-text-underline",
    underlineStrikethrough: "editor-text-underlineStrikethrough",
  },
};
export default editorTheme;
