export const leftArrow = ({ style }) => {
  return (
    <svg
      width="15"
      height="10"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}>
      <path
        d="M0.487662 4.71667C0.558496 4.64584 0.558495 4.57501 0.629328 4.50417L4.87933 0.254172C5.16266 -0.0291616 5.58766 -0.0291616 5.871 0.254172C6.15433 0.537505 6.15433 0.962505 5.871 1.24584L2.82516 4.29167H13.8752C14.3002 4.29167 14.5835 4.57501 14.5835 5.00001C14.5835 5.42501 14.3002 5.70834 13.8752 5.70834H2.82516L5.871 8.75417C6.15433 9.03751 6.15433 9.46251 5.871 9.74584C5.72933 9.88751 5.51683 9.95834 5.37516 9.95834C5.2335 9.95834 5.021 9.88751 4.87933 9.74584L0.629328 5.49584C0.558495 5.42501 0.487662 5.35417 0.487662 5.28334C0.416829 5.07084 0.416829 4.92917 0.487662 4.71667Z"
        fill="#246EFD"
      />
    </svg>
  );
};
