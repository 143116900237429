import moment from "moment";

const dateFormat = "MM-DD-YYYY";

export function removeSnakeCasingAndCapitalize(str) {
  return str.replace(/_/g, " ").toUpperCase();
}
function verifyDate(date) {
  const iso8601Regex =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?([+-]\d{2}:\d{2}|\s*Z)$/;
  return iso8601Regex.test(date);
}

function formatDate(val) {
  const parsedDate = Date.parse(val);
  if (!isNaN(parsedDate)) {
    return moment.utc(`${val}`).format(dateFormat);
  }
  return val;
}

function verifyAndConvertDate(val) {
  if (verifyDate(val)) {
    return formatDate(val);
  }
  return val;
}

function convertKeyAndValue(key, value) {
  if (verifyDate(value)) {
    if (key.toLowerCase() === "datetime") {
      return ["Date", formatDate(value)];
    } else {
      return [key, formatDate(value)];
    }
  } else if (key.toLowerCase() === "height") {
    let convertedValue = value?.replace(/\[lb_av\]/g, "lbs");
    return [key, convertedValue];
  } else if (key.toLowerCase() === "weight") {
    let convertedValue = value?.replace(/\[in_us\]/g, "inch");
    return [key, convertedValue];
  } else {
    return [key, value];
  }
}

let lastVitalsRequiredOrder = [
  "datetime",
  "temperature",
  "blood_pressure",
  "heart_rate",
  "respiration_rate",
  "weight",
  "spo2",
];

function reArrangeLastVitals(obj) {
  const sortedVitalsKeys = [
    ...lastVitalsRequiredOrder,
    ...Object.keys(obj).filter((key) => !lastVitalsRequiredOrder.includes(key)),
  ];
  let sortedVitalsObj = sortedVitalsKeys.reduce((acc, key) => {
    acc[key] = obj[key];
    return acc;
  }, {});
  return sortedVitalsObj;
}
const addH2 = (key, str = "", lowerCase = false) => {
  return (
    str + `<h2>${lowerCase ? key : removeSnakeCasingAndCapitalize(key)}:</h2>\n`
  );
};

const addH3 = (key, str = "") => {
  return str + `<h3>${removeSnakeCasingAndCapitalize(key)}:</h3>\n`;
};

const addListOfStrings = (arr, str = "") => {
  if (arr?.length == 0) return str;
  let result = str + `<ul>\n`;
  arr?.forEach((val) => {
    result += `  <li>${val}</li>\n`;
  });
  result += `</ul>\n`;
  return result;
};

const addKeyValueString = (key, value, str = "") => {
  return (
    str +
    `<p><strong>${removeSnakeCasingAndCapitalize(key)}:</strong> ${value}</p>\n`
  );
};

const addKeyValueList = (obj = {}, str = "") => {
  if (!Object.keys(obj).length) return str;
  let result = str + `<ul>\n`;
  Object.entries(obj).forEach(([k, v]) => {
    if (Array.isArray(v)) {
      result += `  <li><strong>${k}:</strong>\n`;
      result += `    <ul>\n`;
      v.forEach((item) => {
        result += `      <li>${item}</li>\n`;
      });
      result += `    </ul>\n`;
      result += `  </li>\n`;
    } else if (typeof v === "object" && v !== null) {
      result += `  <li><strong>${k}:</strong>\n`;
      result += addKeyValueList(v);
      result += `  </li>\n`;
    } else {
      result += `  <li><strong>${k}:</strong> ${v}</li>\n`;
    }
  });
  result += `</ul>\n`;
  return result;
};

const addTable = (data, str) => {
  if (!Array.isArray(data) || data.length === 0) return str;

  str = "<table>\n";

  // Create table header using the first row of the array.
  str += "  <thead>\n    <tr>\n";
  data[0].forEach((header) => {
    str += `      <th>${header}</th>\n`;
  });
  str += "    </tr>\n  </thead>\n";

  // Create the table body using the rest of the rows.
  str += "  <tbody>\n";
  for (let i = 1; i < data.length; i++) {
    const row = data[i];
    str += "    <tr>\n";
    row.forEach((cell) => {
      str += `      <td>${cell}</td>\n`;
    });
    str += "    </tr>\n";
  }
  str += "  </tbody>\n";
  str += "</table>\n";
  return str;
};

export function convertJsonToHtml(patientData, data, labsData, prescriptions) {
  let mainNoteString = `
    <p>Powered By <strong>Nephrolytics</strong><br/><br/></p>
    <p><strong>Name</strong>: ${patientData?.name}<br/></p>
    <p><strong>Age / Gender</strong>: ${patientData?.age_gender}</p>
    <p><strong>Visit Date</strong>: ${moment(data?.encounter_date).format(dateFormat)}<br/><br/></p>
    `;

  function actionItemSummary(data) {
    let section = "";
    section = addH3("Action Item Summary", section);
    section = addH2("Medications", section);
    const medsMapping = {};

    data?.medication_changes?.forEach((med) => {
      medsMapping[med?.medication] = med?.instructions ?? "";
    });

    prescriptions?.forEach((med) => {
      medsMapping[med?.drug_name] = med?.instructions ?? "";
    });
    section = addKeyValueList(medsMapping, section);

    section = addH2("Labs ordered", section);
    const labMapping = {};
    data?.labs_to_order?.forEach((lab) => {
      labMapping[lab?.lab_name] = lab?.related_diagnosis ?? "";
    });

    labsData?.forEach((lab) => {
      labMapping[lab?.name] = lab?.related_diagnosis ?? "";
    });

    section = addKeyValueList(labMapping, section);

    section = addH2("Referrals", section);
    // Add referral info if available

    section = addH2("Follow-up plan", section);
    section = addListOfStrings(data?.plan, section);

    section = addH2("Billing", section);
    if (data?.cpt_codes) {
      if (Array.isArray(data?.cpt_codes)) {
        section = addListOfStrings(data?.cpt_codes, section);
      } else {
        section = addListOfStrings([data?.cpt_codes], section);
      }
    }
    return section;
  }

  function assessmentAndPlan(data) {
    let section = "";
    section = addH3("Assessment & Plan", section);

    data?.diagnosis_data &&
      Object.entries(data?.diagnosis_data).forEach(([key, diagnoses]) => {
        diagnoses.forEach((diagnosis) => {
          section = addH2(
            `${diagnosis?.category.toLowerCase() !== "other" ? diagnosis?.category : ""} ${diagnosis.condition} (ICD-10: ${diagnosis.icd_10_code})`,
            section,
            true,
          );
          section = addKeyValueList(
            {
              Status: diagnosis.status,
              Plan: diagnosis.plan,
              Guidelines:
                diagnosis.recommendations !== null
                  ? diagnosis.recommendations
                  : "",
            },
            section,
          );
        });
      });

    return section;
  }

  function reasonForVisit(data) {
    let section = "";
    section = addH3("Reason for Visit", section);
    section += `<p>${data?.chief_complaint}</p>\n\n`;
    return section;
  }

  function historyOfPresentIllness(data) {
    let section = "";
    section = addH3("History of Present Illness (HPI)", section);
    section += `<p>${data?.summary_paragraph}</p>\n\n`;
    return section;
  }

  function reviewOfSystems(data) {
    let section = "";
    section = addH3("Review of Systems (ROS)", section);
    section = addKeyValueList(data?.review_of_systems, section);
    return section;
  }

  function physicalExam(data) {
    let section = "";
    section = addH3("Physical Exam (PE)", section);
    section = addKeyValueList(data?.physical_exam, section);
    return section;
  }

  function pastMedicalHistory(data) {
    let section = "";
    section = addH3("Past Medical History (PMH)", section);
    section = addListOfStrings(data?.past_medical_history, section);
    return section;
  }

  function pastSurgicalHistory(data) {
    let section = "";
    section = addH3("Past Surgical History (PSH)", section);
    section = addListOfStrings(data?.past_surgical_history, section);
    return section;
  }

  function familyHistory(data) {
    let section = "";
    section = addH3("Family History", section);
    const familyDataMapping = {};
    data?.family_history?.forEach((fm) => {
      familyDataMapping[fm.relation] = fm.condition;
    });
    section = addKeyValueList(familyDataMapping, section);
    return section;
  }

  function socialHistory(data) {
    let section = "";
    section = addH3("Social History", section);
    section = addListOfStrings(data?.social_history, section);
    return section;
  }

  function allergies(data) {
    let section = "";
    section = addH3("Allergies", section);
    section = addListOfStrings(data?.allergies, section);
    return section;
  }

  function medications(data) {
    let section = "";
    section = addH3("Medications", section);
    section = addListOfStrings(data?.medications, section);
    return section;
  }

  function labs(data) {
    let section = "";
    section = addH3("Labs Summary", section);
    section += addTable(data?.important_labs);
    return section;
  }
  // Concatenate all sections
  mainNoteString += actionItemSummary(data, labs);
  mainNoteString += assessmentAndPlan(data);
  mainNoteString += reasonForVisit(data);
  mainNoteString += historyOfPresentIllness(data);
  mainNoteString += reviewOfSystems(data);
  mainNoteString += physicalExam(data);
  mainNoteString += pastMedicalHistory(data);
  mainNoteString += pastSurgicalHistory(data);
  mainNoteString += familyHistory(data);
  mainNoteString += socialHistory(data);
  mainNoteString += allergies(data);
  mainNoteString += medications(data);
  mainNoteString += labs(data);

  return mainNoteString;
}
