import { FormControl, Grid, InputAdornment, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Text from "../Typography/Text";
import { ErrorTooltip } from "../Tooltips/ErrorTooltip";
import { Copy } from "../SVG/Copy";
import { IconButton } from "../Button/IconButton";

const InputField = ({
  // Cannot provide both value and defaultValue
  // Set this to make this a controlled component
  value,
  // Set this to make this an uncontrolled component
  defaultValue,
  // The label for the input field. This will be displayed above the input field.
  label,
  // The label for the input field. This will be displayed inside the input field (and will float when the input field is focused).
  inputLabel,
  // The name of the input field. This will be used to identify the input field in the form.
  name,
  // Set this to true to make the input field read-only.
  readonly = false,
  min,
  max,
  disabled = false,
  iconDetails = { iconName: null, IconComponent: null },
  onKeyDown,
  type,
  onChange,
  cols,
  xs = 6,
  sm = 6,
  md = 6,
  lg = 6,
  flex = 0,
  formik,
  showError = false,
  errorMessage,
  autoFocus = false,
  onFocus,
  placeholder,
  sxInputProps,
  multiline,
  border = "1px solid #E1E1E1",
  borderRadius = "10px",
  className,
  labelVariant = "bodyS",
  rows,
  // Set custom validators that will run on change. These allow us to add custom validation logic to the input field and control the validation styling.
  validators = [],
  copyable = false,
  ...props
}) => {
  let { iconName, IconComponent, iconProps } = iconDetails;
  const inputRef = useRef(null);

  const [fieldError, setFieldError] = useState(
    formik?.errors[name] || errorMessage,
  );
  const [fieldTouched, setFieldTouched] = useState(
    formik?.touched[name] || showError,
  );

  // Inner value is used to store the value of the input field when no onChange is provided
  const [innerValue, setInnerValue] = useState(value);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  useEffect(() => {
    // Function to get nested values with dot notation or index lookup EX: a.b[0].c or a.b[2][name]
    const getNestedValue = (obj, path) => {
      if (!obj || !path) return null;
      return path
        .split(/[\.\[\]]/)
        .filter((x) => !!x)
        .reduce((acc, part) => {
          if (acc && part) {
            if (part.match(/\d+/)) {
              return acc[parseInt(part)];
            } else {
              return acc[part];
            }
          }
          return null;
        }, obj);
    };

    setFieldTouched(getNestedValue(formik?.touched, name) || showError);
    setFieldError(getNestedValue(formik?.errors, name) || errorMessage);
  }, [formik, showError]);

  const handleChange = (event) => {
    const { value } = event.target;
    setFieldTouched(true);

    if (formik) {
      formik.handleChange(event);
    }

    setInnerValue(value);
    validate(value);

    if (onChange) {
      onChange(event);
    }
    if (props.maxLength && value.length > props.maxLength) {
      return;
    }
  };

  const getSpacing = () => {
    if (cols) {
      return { sm: cols * 3, lg: cols * 2, xl: cols };
    } else {
      return { xs, sm, md, lg };
    }
  };

  const handleKeyUp = (event) => {
    if (event.ctrlKey && event.key === "Backspace") {
      // Handle Ctrl + Backspace key combination
      if (onChange) {
        onChange({
          target: {
            name: name,
            value: event.target.value,
          },
        });
      }
    }
  };

  const validate = (value) => {
    setFieldError(null);
    inputRef.current.setCustomValidity("");
    if (validators.length) {
      for (const validator of validators) {
        const err = validator(value);
        if (err) {
          setFieldError(err);
          inputRef.current.setCustomValidity(err);
        }
      }
    }
  };

  useEffect(() => {
    validate(innerValue);
  }, []);

  // Check validation on default value
  useEffect(() => {
    if (defaultValue) validate(defaultValue);
  }, [defaultValue]);

  return (
    <Grid item {...getSpacing()} flex={flex}>
      {label && (
        <Text variant={labelVariant} fontWeight={500}>
          {label}
        </Text>
      )}
      <ErrorTooltip
        arrow
        title={fieldTouched && fieldError ? fieldError : ""}
        placement="right">
        <FormControl fullWidth variant="outlined" className={className}>
          <TextField
            id={`${name}`}
            name={name}
            autoFocus={autoFocus}
            defaultValue={defaultValue}
            value={innerValue}
            type={type}
            disabled={disabled}
            multiline={multiline}
            label={inputLabel}
            rows={rows}
            data-testid={name}
            onBlur={() => setFieldTouched(true)}
            onKeyDown={onKeyDown}
            onKeyUp={handleKeyUp}
            autoComplete={"off"}
            onFocus={onFocus}
            size="small"
            error={fieldTouched && !!fieldError}
            color="info"
            sx={{ border: border, borderRadius: borderRadius }}
            InputProps={{
              style: { ...sxInputProps },
              autoComplete: "off",
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    cursor: "pointer",
                    marginLeft: "0px",
                    marginRight: "0px",
                  }}>
                  {!!IconComponent && <IconComponent {...iconProps} />}
                  {!!iconName && (
                    <img
                      alt={iconName}
                      data-testid={`input-field-right-icon`}
                      src={iconName}
                    />
                  )}
                  {copyable && (
                    <IconButton
                      variant="text"
                      onClick={() => {
                        navigator.clipboard.writeText(innerValue || "");
                      }}>
                      <Copy />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            placeholder={placeholder}
            inputProps={{
              ref: inputRef,
              "data-testid": `input-${name}`,
              maxLength: props.maxLength,
              minLength: props.minLength,
              min: min,
              max: max,
              ...(readonly ? { readOnly: true } : {}),
            }}
            {...(type === "date" ? { InputLabelProps: { shrink: true } } : {})}
            onChange={handleChange}
            {...formik?.getFieldProps(name)}
          />
        </FormControl>
      </ErrorTooltip>
    </Grid>
  );
};

export default InputField;
